.terminal-settings {
  padding-top: 40px;
  color: #444658;

  &__key {
    font-size: 18px;
    font-weight: 400;
  }

  &__items {
    padding-top: 23px;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 20px;
  }

  & .setting-item {
    border-radius: 15px;
    background: #FAFAFA;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;

    display: flex;
    column-gap: 20px;

    &__title {
      font-size: 18px;
      font-weight: 450;
    }

    &__text {
      font-size: 16px;
      font-weight: 300;
      color: #9E9E9E;
    }

    &__image {
      padding-top: 14px;
    }

    &__control {
      font-size: 14px;
      font-weight: 300;

      display: flex;
      align-items: center;
      column-gap: 10px;

      &--additional form {
        padding-top: 10px;
        display: flex;
        align-items: end;
        column-gap: 17px;

        flex-wrap: wrap;
        row-gap: 10px;

        label {
          font-size: 12px;
          font-weight: 300;
          color: #767676;
          display: flex;
          flex-direction: column;
          row-gap: 5px;

          input {
            border-radius: 5px;
            border: 1px solid #DFDFDF;
            padding: 7px;
            padding-left: 24px;
            max-width: 70px;
            color: #8E8E8E;
          }
        }

        & .btn.btn--add {
          border-radius: 5px;
          border: 1px solid #129A97;
          background: #129A97;
          padding: 6px 17px;
          font-size: 14px;
          font-weight: 300;
          max-width: 95px;
          transition: all 0.4s ease;

          &:hover {
            cursor: pointer;
            background: $white;
            color: $main-green;
            border-color: lighten($main-green, 5%);

          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
  }
}

@media screen and (max-width: 710px) {
  .setting-item {
    flex-direction: column;
    row-gap: 10px;

    &__image {
      margin: 0 auto;
    }

    &__control {
      &--additional {
        flex-direction: column;
        row-gap: 10px;
        align-items: center !important;
        justify-content: center;
      }
    }
  }

}