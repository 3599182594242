// fonts
// @font-face {
//     font-family: 'Roboto Thin';
//     src: url(../fonts/Roboto-Thin.ttf) format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-Medium.woff) format('woff');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-ExtraBold.woff) format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-Heavy.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaPT';
  src: url(../fonts/FuturaPT-Book.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}