.workspace .workspace__title a {
  vertical-align: middle;
  margin-right: 20px;

  &:hover {
    svg {
      path {
        stroke: $main-green;
        transition: stroke 0.4s ease;

      }

      circle: {
        stroke: $main-green;
        transition: stroke 0.4s ease;
      }
    }

  }
}

section[name='terminal'] {
  padding: 0 30px;

  .terminal {
    border-radius: 15px;
    background: $white;
    padding: 30px 26px;

    &-select {
      padding: 20px 0;

      select {
        border: none;
        outline: none;
        background-color: transparent;
      }

    }

    &-list {
      display: flex;
      align-items: center;
      column-gap: 48px;
      margin-bottom: 40px;
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: flex-start;

      &__item {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        font-weight: 400;
        font-size: 20px;
        color: #747474;

        &:hover {
          cursor: pointer;
          color: $main-green;
        }

        &.active {
          color: $main-green;

          div {
            background: $main-green;
          }
        }
      }

      &__line {
        height: 5px;
        border-radius: 20px;
        background: #F7F7F7;
      }
    }

    & .statistics {
      &__item {
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);

        padding: 15px 16px;
      }

      &__item {
        flex-direction: column;
        row-gap: 13px;
      }

      &__info {
        display: flex;
        align-items: center;
        column-gap: 15px;

        p {
          font-size: 15px;
          font-weight: 300;
          color: #2D3748;
        }

        span {
          font-size: 18px;
          font-weight: 400;
        }
      }

      &__img {
        width: 50px;
        height: 50px;
      }
    }

    &-info {
      padding-top: 57px;

      &__title {
        font-size: 20px;
        font-weight: 400;
        color: #444658;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .btn {
          border-radius: 5px;
          border: 1px solid #129A97;
          background-color: #fff;
          color: #444658;
          ;
          padding: 8px 10px;
          font-size: 14px;
          font-weight: 300;
          display: flex;
          column-gap: 10px;
          align-items: center;

          &:hover {
            background-color: #129A97;
            color: #fff;
            transition: background-color 0.4s ease;

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }

      &__body {
        display: flex;
        justify-content: center;
        padding: 37px 30px;
        column-gap: 50px;
        row-gap: 20px;

        .left-block,
        .right-block {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          width: 100%;
        }

        .center-line {
          width: 4px;
          height: auto;
          background-color: #DADADA;
        }

        & .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 20px;

          font-weight: 300;
          font-size: 14px;
          color: #2D3748;

          &__text {
            color: #000;
          }

          &__text--green {
            color: $main-green;
          }

          &__text--red {
            color: #FF6C7D;
          }

          &__line {
            height: 1px;
            border-bottom: 1px dashed #D6D6D6;
            flex-grow: 1;
            // width: max-content;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 1167px) {
  .terminal-info__body {
    flex-wrap: wrap;

    .center-line {
      width: 100% !important;
      height: 2px !important;
    }
  }
}

@media screen and (max-width: 790px) {
  section[name='terminal'] {
    padding: 0 15px;
  }

  .terminal {
    padding: 15px 13px !important;
  }

  .terminal-info__body {
    padding: 18px 15px !important;
  }

  .terminal-info__header {
    flex-direction: column;
    row-gap: 20px;
  }

  .terminal-list {
    &__item {
      font-size: 16px !important;
    }
  }
}