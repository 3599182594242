.terminal.partners.partners-card {
  & .terminals-table.terminals-table--report {
    padding-top: 1px;
  }

  & .pagination-wrapper {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .pagination a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
    }

    /* Стиль активной/текущей ссылки */
    & .pagination a.active {
      background-color: $main-green;
      color: white;
    }

    /* Добавить серый цвет фона при наведении курсора мыши */
    & .pagination a:hover:not(.active) {
      background-color: #ddd;
    }
  }

  & .table-row__policy {
    font-weight: 400;
  }
}

@media screen and (max-width: 676px) {
  .terminal.partners.partners-card {
    & .pagination-wrapper {
      justify-content: center;

      & .pagination a {
        padding: 4px 8px;
      }
    }
  }
}