body {
  background: linear-gradient(258deg, #129A97 27.11%, #78DCD2 93.64%);
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;

  & .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.6rem;
    color: $white;

    &__title {
      font-size: 40px;
      font-weight: 450;
      margin: 0;
    }

    &__img>svg {
      flex-shrink: 0;
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 85px;
  padding-right: 85px;
  padding-top: 35px;
  padding-bottom: 55px;
  background-color: $white;

  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);

  & h2 {
    color: $primary-black;
    font-size: 25px;
    font-weight: 450;

    margin-bottom: 22px;
  }

  & .inputs-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    // margin-bottom: 18px;
    margin-bottom: 30px;

    & input {
      border-radius: 10px;
      padding: 15px 0 15px 44px;
      min-width: 325px;

      font-size: 16px;
      color: #555;
      font-weight: 400;

      border: 1px solid rgba(23, 158, 154, 0.25);
      background: rgba(244, 244, 244, 0.50);

      background-position: center left 14px;
      background-repeat: no-repeat;

      &:focus {
        border-color: rgba(15, 134, 130, 0.5);
        transition: border-color 0.4s ease;
      }

      &[type="email"] {
        background-image: url("../../assets/images/svg/login-email.svg");
      }

      &[type="password"] {
        background-image: url("../../assets/images/svg/login-password.svg");
      }

      &.validate-error {
        border-color: #FF6C7D;
      }

      &::placeholder {
        color: #A0AEC0;
      }
    }

    & label {
      display: flex;
      flex-direction: column;
      row-gap: 7px;

      font-weight: 400;
      font-size: 12px;
      color: #A0AEC0;

      position: relative;

      & a {
        position: absolute;
        right: 0px;
        top: -1px;
        color: #555;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  & .error {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    column-gap: 8px;

    span {
      color: #FF6C7D;
      font-size: 12px;
      font-weight: 450;
    }

    img {
      width: 17px;
      height: 17px;
    }
  }

  & .btn--back {
    background-color: transparent;
    color: $main-green;
    border: 1px solid $main-green;
    margin-top: 10px;
  }
}

@media screen and (max-width: 527px) {
  .login-form {
    padding-left: 14px;
    padding-right: 14px;
    width: 100%;
    border-radius: 0px;

    & .inputs-wrapper {
      width: 100%;

      & input {
        min-width: 274px;
        max-width: 100%;
      }
    }

    h2 {
      font-size: 20px;
    }
  }

  .login {
    & .logo {
      &__title {
        font-size: 30px;
      }
    }
  }
}