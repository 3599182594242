.terminal.partners.terminal-notification {
  & .notification-create__form {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    & input,
    & textarea {
      border-radius: 5px;
      border: 1px solid #DFDFDF;
      padding: 12px 13px;
      max-width: 390px;
      // flex-shrink: 0;
      width: 100%;

      &::placeholder {
        font-size: 12px;
        font-weight: 300;
        color: #8E8E8E;
      }
    }

    & textarea {
      resize: none;
    }

    & button {
      max-width: 390px;
      padding: 11px 50px;
      font-size: 14px;
      font-weight: 300;
      border-radius: 5px;
    }

  }
}