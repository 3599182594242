// Стилизация скролла для Mozilla Firefox
*,
*::before,
*::after {
  scrollbar-color: #129A97 #000;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
  // (варианты: auto | thin | none;)
}

// Стилизация скролла для webkit браузеров
/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 10px;
  /* ширина для вертикального скролла */
  height: 10px;
  /* высота для горизонтального скролла */
  background-color: #000;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #129A97;
  border-radius: 10px;
  border: 3px solid black;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #157573;
}