.nav {
  display: flex;
  flex-direction: column;
  min-width: 300px;

  padding-top: 54px;
  padding-bottom: 28px;

  & .logo {
    transition: all 0.8s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.6rem;
    color: $main-green;
    padding-bottom: 24px;
    padding-left: 43px;
    padding-right: 43px;

    &__title {
      font-size: 25px;
      font-weight: 450;
      margin: 0;
    }

    &__img {
      width: 28px;
      height: 33px;
    }

    &__img>svg {
      flex-shrink: 0;
    }
  }

  &__hr-line {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__links {
    transition: all 0.8s ease-in-out;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding-top: 52px;
    padding-bottom: 24px;
    padding-left: 43px;

    & .link-item {
      transition: all 0.8s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &__link {
        transition: all 0.8s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        font-size: 18px;
        font-weight: 450;
        color: #444658;
        flex-grow: 1;

        transition: color 0.4s ease;

        &.active,
        &:hover {
          color: $main-green;

          svg path {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }

          svg circle {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }

          svg rect {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }
        }

        &.active {
          +.link-item__line {
            background-color: $main-green;
          }
        }
      }

      &__count {
        border-radius: 4px;
        background: #129A97;
        font-size: 12px;
        font-weight: 600;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: 150%;
        margin-right: 8px;
      }

      &__line {
        width: 3px;
        height: 100%;
        border-radius: 5px;
        background-color: transparent;

        &.active {
          background-color: $main-green;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // row-gap: 72px;

    padding-top: 30px;
    padding-right: 43px;
    padding-left: 43px;

    & .instructions {
      width: 100%;
      // padding-left: 43px;

      margin-bottom: 70px;

      &__link {
        display: flex;
        align-items: center;
        column-gap: 20px;
        font-weight: 450;
        font-size: 18px;
        transition: color 0.4s ease;

        &:hover {
          color: $main-green;

          svg path {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }
        }
      }
    }

    & .help {
      transition: all 0.8s ease-in-out;
      flex-grow: 1;
      background-color: $main-green;
      background-image: url("../../assets/images/svg/help-elipses.svg");
      background-repeat: no-repeat;
      background-position: center right;

      margin-bottom: 9px;
      padding: 16px 16px 0 16px;
      padding-bottom: 50px;
      border-radius: 15px;
      color: $white;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0.4px;

        margin-bottom: 7px;
      }

      a {
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;

        &:hover {
          text-decoration: underline;
        }
      }

      &__icon {
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

    .copyright {
      transition: all 0.8s ease-in-out;
      font-size: 10px;
      color: #A7A7A7;
      font-weight: 400;
      flex-grow: 1;

      margin-bottom: 100px;
    }

    & .btn-collapse {
      display: flex;
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      padding: 0;

      font-size: 20px;
      font-weight: 300;
      color: #444658;

      svg {
        will-change: transform;
        transition: transform 0.4s ease;
      }

      &:hover {
        color: $main-green;
        transition: color 0.4s ease;

        svg path {
          stroke: $main-green;
          transition: stroke 0.4s ease;
        }
      }

      &.btn-collapse--active {
        justify-content: flex-start;
        padding-left: 0px;

        svg path {
          stroke: $main-green;
        }

        svg {
          transform: rotate(180deg);
        }

        span {
          display: none;
        }
      }
    }
  }

  transition: all 0.8s ease-in-out;

  &.collapsed {
    transition: all 0.8s ease-in-out;
    // min-width: 115px;
    // max-width: 115px;
    // padding-right: 0;

    .logo__title {
      // display: none;
      // width: 0;
      // opacity: 0;
      // overflow: hidden;
      transition: all 0.8s ease-in-out;
    }

    .help {
      // display: none;
      // width: 0;
      // opacity: 0;
      // overflow: hidden;
      transition: all 0.8s ease-in-out;
    }

    .nav__footer {
      padding-right: 0px;
      // padding-left: 30px;
    }

    .link-item {
      span {
        // display: none;
        // width: 0;
        // opacity: 0;
        // overflow: hidden;
        transition: all 0.8s ease-in-out;
      }

      &__count {
        // display: none;
        // width: 0;
        // opacity: 0;
        // overflow: hidden;
        transition: all 0.8s ease-in-out;
      }
    }

    .instructions__link span {

      // display: none;
      // width: 0;
      // opacity: 0;
      // overflow: hidden;
      transition: all 0.8s ease-in-out;
    }

    .copyright {

      // display: none;
      // width: 0;
      // opacity: 0;
      overflow: hidden;
      transition: all 0.8s ease-in-out;
    }
  }
}

@keyframes collapse {
  from {
    width: 100%;
    opacity: 1;
  }

  to {
    width: 0;
    opacity: 0;
    display: none;
    overflow: hidden;
  }
}