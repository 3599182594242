.workspace__content.filters-system {
  & .terminal-select.terminal-select--filters {
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;

    & select {
      border-radius: 40px;
      border: 1px solid #DFDFDF;
      padding: 7px 12px;
    }
  }

  & .terminal.partners.partners-card {
    & .terminals-table.terminals-table--history {
      & .table__th {
        color: #A0AEC0 !important;
      }

      & .table-row__td p {
        font-weight: 400;
        color: #444658;
      }
    }
  }
}

@media screen and (max-width: 847px) {
  .workspace__content.filters-system {
    & .terminal-select.terminal-select--filters {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

@media screen and (max-width: 627px) {
  .workspace__content.filters-system {
    & .terminal-select.terminal-select--filters {
      & select {
        width: 100%;
      }
    }
  }
}