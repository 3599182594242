section[name='terminal'] {
  padding: 0 30px;

  & .terminal {

    & .table-row__policy {
      font-size: 16px;
    }

    & .table-row.table-row--chris.result td,
    .table-row.table-row--chris.result td p {
      color: #2D3748;
      font-size: 16px;
      font-weight: 450;
    }

    & .table-row__policy--error {
      color: #FF6C7D;

      .error {
        position: relative;

        &__text {
          display: none;
          position: absolute;
          top: -42px;
          left: -91px;

          border-radius: 5px;
          border: 1px solid #129A97;
          background: #FFF;
          padding: 3px;
          font-size: 12px;
          font-weight: 300;
          color: #676767;
          line-height: 1.1;

          svg {
            position: absolute;
            bottom: -7px;
            right: 8px;
          }
        }

        &:hover {
          cursor: pointer;

          & .error__text {
            display: block;
          }
        }
      }

      img {
        height: 11px;
        width: 11px;
        margin-left: 5px;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }

  & .terminal-list {
    margin-bottom: 0;
  }

  & .terminal-info {
    padding-top: 45px;

    @media screen and (max-width: 976px) {
      &__header {
        flex-direction: column;
        row-gap: 15px;
      }
    }

    &__control--transactions {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      & input {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: #fff;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        padding: 8px 26px;
        border: 1px solid;
        border-color: #DFDFDF;
        font-weight: 300;
        font-size: 10px;

        &::-webkit-calendar-picker-indicator {
          color: transparent;
          opacity: 1;
          background: url('../../assets/images/svg/date-icon.svg') no-repeat center;
          background-size: contain;
          order: 2;

          &:hover {
            cursor: pointer;
          }
        }

        &:focus {
          border-color: $main-green;
          transition: border-color 0.4s ease;
        }
      }

      & .chart__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: #fff;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        padding: 8px 26px;
        color: $main-green;
        border: 1px solid;
        border-color: $main-green;
        font-weight: 400;
        font-size: 10px;

        &:hover {
          background: lighten($main-green, 5%);
          color: $white;
          border-color: lighten($main-green, 5%);
        }
      }

      @media screen and (max-width: 762px) {
        flex-direction: column;
        row-gap: 15px;
      }
    }
  }
}

tr td:not(:first-child) {
  text-align: center;
}

tr th:not(:first-child) {
  text-align: center;
}