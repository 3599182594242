section[name="accounting"] {
  .accounting {
    display: flex;
    column-gap: 70px;

    border-radius: 15px;
    background: #FFF;
    padding: 40px 34px;

    &-data {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 100%;

      &__item {
        color: #2D3748;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        hr {
          margin: 0;
          height: 1px;
          background: #E2E8F0;

          margin-top: 10px;
          border: none;
          outline: none;
        }

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      &__year {
        font-weight: 20;
        font-weight: 450;
      }

      &__text-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 6px;
      }
    }

    &-info {
      border-radius: 0px 10px 10px 0px;
      border: 1px solid #EDF1FF;
      background: #F5FFFF;
      padding: 15px 24px;
      color: #333F5D;
      margin-top: 24px;

      border-left: 6px solid #DFF;

      display: flex;
      flex-direction: column;
      row-gap: 13px;


      &__title {
        font-size: 20px;
        font-weight: 600;
      }

      &__subtitle {
        font-weight: 450;
      }

      &__items {
        display: flex;
        flex-direction: column;
        row-gap: 17px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        row-gap: 3px;

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.1;
        }
      }
    }
  }
}

@media screen and (max-width: 927px) {
  section[name="accounting"] {
    padding-left: 10px;
    padding-right: 10px;

    .accounting {
      padding-left: 10px;
      padding-right: 10px;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}