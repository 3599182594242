.hopper {
  display: flex;
  padding-top: 40px;
  column-gap: 40px;

  &-info {
    display: flex;
    flex-direction: column;

    &__text {
      padding-top: 20px;
      text-align: center;
      color: #444658;
      font-weight: 300;

    }

    padding-top: 26px;
    padding-bottom: 18px;
    padding-right: 29px;
    padding-left: 29px;
    border-radius: 15px;
    background: #FAFAFA;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

    &__body {
      display: flex;

      p {
        font-size: 16px;
        font-weight: 400;
        color: #444658;
      }

      span {
        font-weight: 300;
      }

      .left,
      .right {
        display: flex;
        flex-direction: column;
        width: 120px;

        &__item {
          padding-left: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: dashed 1px rgba(0, 0, 0, 0.1);

          display: flex;
          flex-direction: column;
          row-gap: 25px;
        }
      }

      .left {
        &__item {
          border-right: dashed 1px rgba(0, 0, 0, 0.1);

          p {
            margin-left: -15px;
          }
        }
      }

      .right {
        text-align: center;
      }
    }
  }

  &-control {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    &__title {
      font-size: 20px;
      font-weight: 400;
    }

    &__progress {
      display: flex;
      flex-direction: column;
      row-gap: 6px;

      progress {
        appearance: none;
        height: 6px;
        // width: 100%;
      }

      progress::-webkit-progress-value {
        background-color: $main-green;
        border-radius: 16px;
      }

      progress::-webkit-progress-bar {
        background-color: #E2E8F0;
        border-radius: 16px !important;
      }

      span {
        color: $main-green;
      }
    }

    &__button {
      display: flex;
      column-gap: 20px;
      align-items: center;

      .img-wrapper {
        position: relative;

        &:hover {
          cursor: pointer;

          .error {
            display: block;
          }
        }

        .error {
          display: none;
          width: 274px;
          position: absolute;
          padding: 9px 13px;
          font-size: 14px;
          font-weight: 300;
          border-radius: 5px;
          border: 1px solid #129A97;
          z-index: 999;

          bottom: 24px;
          left: -8px;


          svg {
            position: absolute;
            bottom: -6px;
            left: 10px;
          }
        }
      }

      img {
        width: 17px;
        height: 17px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    & .btn {
      padding: 10px 12px;
      border-radius: 5px;
      border: 1px solid #129A97;
      background-color: #fff;
      color: #129A97;
      font-size: 14px;
      font-weight: 300;

      &:hover {
        background-color: #129A97;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1110px) {
  .hopper {
    flex-direction: column;
    width: fit-content;
    row-gap: 20px;
  }

  .hopper-control .btn {
    width: max-content;
  }
}