.accounting-info.accounting-info--shrink {
  flex-shrink: 0;

  & .accounting-info__items {
    & .accounting-info__item-wrapper {
      display: flex;
      column-gap: 4px;

      p {
        flex-shrink: 0;
      }
    }
  }



}

.accounting-data__text-block {
  & .accounting-data__text {
    & select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    & select {
      border: none;
      outline: none;
      border-bottom: 1px dashed #000;
      padding-bottom: 6px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 678px) {
  .accounting-info.accounting-info--shrink {
    h3 {
      text-align: center;
    }

    & .accounting-info__items {
      & .accounting-info__item-wrapper {
        flex-direction: column;
        align-items: center;
        row-gap: 4px;

        p {
          font-weight: 500;
        }
      }
    }
  }
}

.accounting-search {
  padding: 0 30px;
  padding-bottom: 16px;

  & form {
    display: flex;
    align-items: center;
    column-gap: 12px;

    & input {
      font-size: 12px;
      font-weight: 300;
      border-radius: 5px;
      border: 1px solid #DFDFDF;
      padding: 12px 16px;
      min-width: 180px;
      height: 100%;

      &::placeholder {
        color: #8E8E8E;
      }
    }

    & button {
      border-radius: 5px;
      border: 1px solid #129A97;
      background: #129A97;
      padding: 11px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      transition: all 0.4s ease;

      &:hover {
        background: #fff;
        color: #129A97;
        border: 1px solid #129A97;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .accounting-search {
    padding: 0 30px;
    padding-bottom: 16px;

    & form {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}