.terminal {
  .terminal-info {
    &__header {
      & .btn.btn--add {
        border: 1px solid #129A97;
        background: #129A97;
        color: $white;
        padding: 7px 12px;

        &:hover {
          background: $white;
          color: $main-green;
          border-color: $main-green;
          transition: all 0.4s ease;

          svg {
            * {
              stroke: $main-green;
              transition: all 0.4s ease;
            }
          }
        }
      }
    }
  }
}