.workspace__content {
  &.filters-system {
    .terminal.partners.partners-card {
      // display: flex;
      // flex-direction: column;
      // row-gap: 20px;
    }

    & .filters-system__list {
      padding-top: 30px;
    }

    & .terminal-select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      a.btn.btn--add {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 145px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 5px;
        border: 1px solid #129A97;
        background: #129A97;
        font-weight: 400;
        font-size: 14px;
      }
    }

    & .filters-system__alert {
      padding: 12px 20px;
      border-radius: 10px;
      border: 2px dashed rgba(18, 154, 151, 0.50);
      background: #FFF;
      font-size: 16px;
      font-weight: 300;
      color: #444658;
    }

    & .filters-system__subtitle {
      font-size: 20px;
      font-weight: 400;
      color: #444658;
    }

    .terminal-list__image-wrapper {
      height: 44px;
      width: 44px;
    }

    .hopper-control__progress {
      align-items: flex-start;

      span {
        font-weight: 400 !important;
      }
    }

    .hopper-control__progress.hopper-control__progress--warning {
      span {
        color: #FF6C7D;
      }

      progress::-webkit-progress-value {
        background-color: #FF6C7D;
      }
    }

    .hopper-control__progress.hopper-control__progress--attention {
      span {
        color: #E1D800;
      }

      progress::-webkit-progress-value {
        background-color: #E1D800;
      }
    }

    .hopper-control__progress.hopper-control__progress--ok {
      span {
        color: $main-green;
      }

      progress::-webkit-progress-value {
        background-color: $main-green;
      }
    }

    .table-row__td p {
      font-weight: 300;
    }

    .table__th {
      color: #444658;

    }

    .table-row__td span {
      font-size: 14px;
      font-weight: 300;
      color: #979797;
    }

    .table-row__td.table-row__td--warning p {
      color: #FF6C7D;
      font-size: 14px;
      font-weight: 300;
    }

    .table-row__td.table-row__td--ok p {
      color: $main-green;
      font-size: 14px;
      font-weight: 300;
    }

    .table-row__td.table-row__td--attention p {
      color: #E1D800;
    }

    .table-row__td button {
      font-weight: 400;
      font-size: 14px;
      padding: 8px 18px;
      border-radius: 5px;
      max-width: 150px;
      min-width: 160px;
    }
  }
}

.modal.modal--cancel {
  & .form.form--add-plane {
    row-gap: 8px;

    p:first-child {
      color: #444658;
      font-size: 16px;
      font-weight: 300;
    }

    row-gap: 10px;

    & .inputs-wrapper {
      padding-top: 10px;
      flex-direction: row;
      column-gap: 8px;

      button:last-child {
        border: 1px solid #424242;
        background: #424242;

        &:hover {
          background: lighten(#424242, 10%);
        }
      }
    }
  }
}



@media screen and (max-width: 1240px) {
  .workspace__content {
    &.filters-system {
      & .table-row__td {
        button {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }

        .terminal-list__image-wrapper {
          margin: 0 auto;
        }

        .hopper-control__progress {
          align-items: center;
        }
      }

      & .terminal .terminals-table.terminals-table--report {
        padding: 10px 15px 20px;

      }

    }
  }
}