// reset styles;
@import "vendors/reset";
// normalize styles
@import "vendors/normalize.scss";
// scrollbar
@import "scrollbar";
// tooltips
@import "tooltips";
// fonts
@import "fonts";
// variables
@import "vars";
// mixins
@import "mixins";
// helpers
@import "helpers";


// default styles
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: $white;
  font-family: 'FuturaPT', sans-serif;
  font-size: 1.6rem;
  color: $black;
  height: 100%;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;

  // Плавный скролл
  scroll-behavior: smooth;

  // Сглаживание шрифтов
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;

  // Fix размытия изображений в Chromium
  image-rendering: -webkit-optimize-contrast;

  font: inherit;
  font-size: inherit;
  color: inherit;

  // outline default false
  outline: none;
}

// Вытягиваем все картинки по ширине и убираем отступ
img {
  vertical-align: top;
  object-fit: cover;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

// end default styles

.btn {
  outline: none;
  border: 1px transparent;
  width: 100%;
  padding: 14px;
  border-radius: 12px;
  color: $white;
  font-size: 20px;
  font-weight: 450;
  background: $main-green;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.1);
    background: lighten($main-green, 5%);
  }
}



@media screen and (max-width: 527px) {
  .btn {
    font-size: 16px;
  }
}

// toggle checkbox
// checkbox
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 23px;
  min-width: 38px;

}

/* hide custom checkbox and apply custom style to make toggle switch */
.switch input {
  display: none;
}

.slider {
  /* style toggle switch itself */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FF6C7D;
}

.slider::before {
  /* style toggle switch knob when OFF */
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1.34px;
  background-color: #fff;
  transition: 0.4s;
}

input[type="checkbox"]:checked+.slider::before {
  /* style toggle switch knob when ON */
  -webkit-transform: translateX(15.2px);
  -ms-transform: translateX(15.2px);
  transform: translateX(15.2px);
}

input[type="checkbox"]:checked+.slider {
  /* style toggle switch background when ON */
  background-color: #129A97;
}

/* apply border-radius to switch */
.slider.round {
  border-radius: 16px;
}

.slider.round::before {
  border-radius: 50%;
}

// end toggle checkbox

/* Blocks */
@import 'blocks/header';
@import 'blocks/container';
@import 'blocks/login';
@import 'blocks/wrapper';
@import 'blocks/nav';
@import 'blocks/workspace';
@import 'blocks/content';
@import 'blocks/user';
@import 'blocks/analytic';
@import 'blocks/terminals';
@import 'blocks/terminal';
@import 'blocks/terminal-id-transactions';
@import 'blocks/terminal-id-prices';
@import 'blocks/terminal-id-hopper';
@import 'blocks/terminal-id-collection';
@import 'blocks/terminal-id-settings';
@import 'blocks/water-price';
@import 'blocks/filters-system';
@import 'blocks/filters-system-history';
@import 'blocks/notifications';
@import 'blocks/finance-transactions';
@import 'blocks/accounting';
@import 'blocks/admin-partners';
@import 'blocks/admin-partners-card';
@import 'blocks/admin-accounting';
@import 'blocks/admin-report-filters';
@import 'blocks/admin-notifications';
@import 'blocks/admin-notifications-id';
@import 'blocks/admin-notifications-create';
@import 'blocks/footer';


section {
  padding: 0 30px;
}

@media screen and (max-width: 790px) {
  section {
    padding: 0 15px !important;
  }
}