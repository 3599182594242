section[name='user'] {
  padding: 0 30px;

  .user {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    border-radius: 15px;
    background: $white;
    padding: 35px;

    &__title {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }

    &__data {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    .inputs-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 16px;
      row-gap: 16px;
      flex-wrap: wrap;

      & input {
        border-radius: 5px;
        border: 1px solid #DFDFDF;
        padding: 0 16px;
        padding-top: 6px;
        padding-bottom: 10px;

        &::placeholder {
          font-size: 12px;
          font-weight: 300;
          color: #8E8E8E;
          line-height: normal;
        }
      }
    }

    &__btn {
      width: 145px;
      padding: 7px 0;
      font-weight: 300;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}