.btn.btn--delete {
  font-size: 14px;
  font-weight: 300;
  color: #FF6C7D;
  // padding: 7px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  column-gap: 10px;
  border: 1px solid #FF6C7D;
}

.workspace.partners {
  & .workspace__header {
    column-gap: 8px;
  }

  & .workspace-wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;

    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;

    h2 {
      flex-shrink: 0;
      margin-right: 10px;
    }

    a,
    button {
      min-width: 145px;
      max-width: min-content;
      padding: 7px 12px;

      svg {
        min-width: 18px;
        min-height: 18px;
        max-width: 18px;
        max-height: 18px;
      }
    }
  }
}

.terminal.partners.partners-card {
  background-color: #FBFBFB;
  ;
  padding-left: 0 !important;
  padding-right: 0 !important;

  & .btn.btn--add {
    background-color: #fff;
    color: $main-green;
  }

  & .terminal-info__title {
    font-size: 25px;
  }

  & .terminals-table {
    border-radius: 15px;
    background: #FFF;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-top: 24px;
  }

  & .profile-data {
    display: flex;
    column-gap: 20px;

    &__form {
      border-radius: 15px;
      background: #FFF;
      padding: 35px;
    }

    & form {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      &-block {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &__title {
          font-size: 16px;
          font-weight: 400;
          color: #000;
        }
      }

      & .form-block {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }

      & .inputs-wrapper {
        display: flex;
        column-gap: 16px;
        align-items: center;

        & input {
          border-radius: 5px;
          border: 1px solid #DFDFDF;
          padding: 7px 17px;
          font-size: 12px;
          font-weight: 300;

          &::placeholder {
            color: #8E8E8E;
          }

          &:focus {
            border-color: $main-green;
          }
        }
      }

      & button {
        max-width: 160px;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      & .change-password,
      & .authlist {
        padding: 30px 25px;
        border-radius: 15px;
        background: #FFF;
      }

      & .change-password {
        display: flex;
        column-gap: 40px;
        align-items: center;

        &__control {
          display: flex;
          column-gap: 10px;
          align-items: flex-end;

          button {
            padding-top: 6px;
            padding-bottom: 6px;
          }

          & label {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          }

          & input {
            border-radius: 5px;
            border: 1px solid #DFDFDF;
            padding: 7px 17px;
            font-size: 12px;
            font-weight: 300;

            &::placeholder {
              color: #8E8E8E;
            }

            &:focus {
              border-color: $main-green;
            }
          }
        }
      }

      & .authlist {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        &__list {
          display: flex;
          flex-direction: column;
          row-gap: 14px;

          &__date {
            border-right: 1px solid #a6adb6;
            padding-right: 6px;
          }

          &__info {
            padding-left: 6px;
          }
        }


        &__item {
          display: flex;
          padding-bottom: 14px;
          font-size: 14px;
          font-weight: 300;
        }

        &__item:not(:last-child) {
          border-bottom: 1px solid #E2E8F0;
        }
      }
    }
  }

  .terminal-list {
    margin-top: 20px;
    border-radius: 15px;
    background: #FFF;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &__item {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      border-radius: 15px;
      background: #FAFAFA;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      padding: 20px 28px;
      font-size: 16px;
      font-weight: 300;
      color: #444658;

      &:hover {
        cursor: default;

        p {
          color: #444658;

        }
      }
    }

    // &__form {
    //   width: 100%;
    //   display: grid;
    //   grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 30px;

    //   border-radius: 15px;
    //   background: #FAFAFA;
    //   box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    //   padding: 20px 28px;
    //   font-size: 16px;
    //   font-weight: 300;
    //   color: #444658;

    //   &:hover {
    //     cursor: default;

    //     p {
    //       color: #444658;

    //     }
    //   }
    // }



    &__subitem {
      .terminal-list__image-wrapper {
        min-width: 44px;
        position: relative;

        &::after {
          // content: '10';
          content: attr(data-count);
          font-size: 10px;
          font-weight: 300;
          line-height: 15px;
          text-align: center;
          display: block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #129A97;
          margin: 0 auto;
          margin-bottom: 10px;
          position: absolute;
          top: 0.2px;
          right: 0.2px;
          color: $white;
        }
      }

      display: flex;
      column-gap: 23px;
      align-items: center;

      &:not(:first-child) {
        justify-content: center;
      }

      & input {
        border-radius: 5px;
        border: 1px solid #D9D9D9;
        padding-top: 9px;
        padding-bottom: 9px;
        text-align: center;
      }

      & a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & button,
      & a {
        font-size: 14px;
        font-weight: 300;
        padding-top: 9px;
        padding-bottom: 9px;
        border-radius: 5px;
        border: 1px solid #129A97;
        background: #FAFAFA;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

        &:hover {
          cursor: pointer;
          background: #129A97;
          color: #fff;
          box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .terminal.partners.partners-card {

    .profile-data {
      flex-direction: column;
      row-gap: 20px;
    }
  }

}

@media screen and (max-width: 767px) {
  .terminal.partners.partners-card {
    .profile-data__form {
      padding: 20px;

      & .inputs-wrapper {
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start !important;

        &.switch-wrapper {
          flex-direction: row;
          align-items: center !important;
        }
      }
    }

    .change-password {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

.modal.modal--add-plane.modal__partner-card {
  max-width: 540px;

  & .modal__content {
    & input[name="login"] {
      background: #EAEAEA;
    }

    & .setting-item__control {
      h3 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      & .switch-list {
        display: flex;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
      }
    }
  }
}

.modal.modal--success.modal__partner-card {
  max-width: none !important;

  .modal-wrapper {
    row-gap: 15px !important;
    align-items: flex-start !important;

    p {
      font-size: 20px !important;
      font-weight: 400 !important;
    }
  }

  .modal__content.modal__partner-card {
    padding-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 40px;

    & label {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      font-size: 16px;
      font-weight: 300;

      .label-wrapper {
        display: flex;
        align-items: center;
        column-gap: 6px;
      }

      button {
        background-color: transparent;
        outline: none;
        border: none;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
      }
    }

    & input {
      border-radius: 5px;
      border: 1px solid #DFDFDF;
      padding: 7px 12px;
      min-width: 250px;
      color: #000;
      font-weight: 400;
    }

    .btn.modal__btn.btn--add {
      margin: 0;
    }
  }
}