.btn.btn--add.btn-partners {
  padding: 8px 18px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background-color: #fff;
  color: #444658;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.workspace__title.partners-title {
  display: flex;
  align-items: center;
  column-gap: 29px;
  line-height: 1.3;
}

section[name=terminal] .terminal {
  &.partners {
    padding: 14px 30px;

    .partners-control {
      display: flex;
      column-gap: 4px;
      align-items: center;
      justify-content: center;

      button {
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
      }
    }
  }
}

.wrapper.admin {
  .modal {
    padding-top: 38px;
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 25px;
    overflow: hidden;

    max-width: none;
    min-width: 35dvw;
    // width: 800px;

    .modal__content {
      overflow-y: auto;
      padding-right: 8px;

      & .form.form--add-plane {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      & .inputs-wrapper {
        &.partner-inputs {
          row-gap: 20px;

          & .partner-inputs__data,
          & .partner-inputs__info,
          & .partner-inputs__contacts {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
          }

          & .partner-inputs__wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
            gap: 16px;

            & input {
              padding: 7px 17px;
              padding-right: 0;
            }

            & label[for="password"] {
              display: flex;
              align-items: center;
              column-gap: 8px;

              .generate-pass {
                border: none;
                outline: none;
                background-color: $main-green;
                border-radius: 50%;
                width: 28px !important;
                height: 28px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
                flex-shrink: 0;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      & .setting-item__control {
        flex-direction: column;
        row-gap: 10px;
        font-size: 14px;
        font-weight: 300;
        color: #444658;

        & .additional-wrapper {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }

      & .btn.modal__btn.btn--add {
        max-width: 160px;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }

  .modal.modal--success {
    max-width: 360px;
    min-width: 300px;

    & .modal-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      align-items: center;

      img {
        max-width: 152px;
        max-height: 152px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #000;
      }
    }

    &__content {
      row-gap: 19px;
    }
  }
}

.modal__content::-webkit-scrollbar {
  margin-right: 20px;
  width: 6px;
  /* ширина для вертикального скролла */
  height: 6px;
  /* высота для горизонтального скролла */
  background-color: #000;
}

/* ползунок скроллбара */
.modal__content::-webkit-scrollbar-thumb {
  margin-right: 20px;
  background-color: #129A97;
  border-radius: 10px;
  border: 2px solid transparent;
}

.modal__content::-webkit-scrollbar-thumb:hover {
  background-color: #157573;
}