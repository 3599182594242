section[name='analytic'] {
  padding: 0 30px;

  .analytic {

    // statisctics
    & .chart {
      padding-top: 40px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;

        h3 {
          font-size: 25px;
          font-weight: 400;
        }
      }

      &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15px;

        & input {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          background: #fff;
          box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
          padding: 8px 26px;
          border: 1px solid;
          border-color: #DFDFDF;
          font-weight: 300;
          font-size: 10px;

          &::-webkit-calendar-picker-indicator {
            color: transparent;
            opacity: 1;
            background: url('../../assets/images/svg/date-icon.svg') no-repeat center;
            background-size: contain;
            order: 2;

            &:hover {
              cursor: pointer;
            }
          }

          &:focus {
            border-color: $main-green;
            transition: border-color 0.4s ease;
          }
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: #fff;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        padding: 8px 26px;
        color: $main-green;
        border: 1px solid;
        border-color: $main-green;
        font-weight: 400;
        font-size: 10px;

        &:hover {
          background: lighten($main-green, 5%);
          color: $white;
          border-color: lighten($main-green, 5%);
        }
      }


      &__graph {
        padding: 25px;
        border-radius: 15px;
        background: #fff;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

      }
    }
  }
}

.statistics {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 400px));
  gap: 30px;

  &__info {
    p {
      font-size: 12px;
      font-weight: 700;
      color: #A0AEC0;
      line-height: 150%;
    }

    & :nth-child(2) {
      font-size: 18px;
      font-weight: 500;
      color: $black;
      margin-right: 4px;
      line-height: 140%;
    }

    & :nth-child(3) {
      color: $main-green;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

    padding: 20px 25px;
  }

  &__img {
    border-radius: 12px;
    background: #129A97;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    padding: 13.4px;
  }
}

@media screen and (max-width: 940px) {
  .chart {
    &__header {
      flex-direction: column;
      row-gap: 15px;
    }

    &__controls {}
  }

}

@media screen and (max-width: 757px) {
  .chart {
    &__controls {
      flex-direction: column;
      row-gap: 15px;
    }
  }

}