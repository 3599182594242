// .workspace {
//   display: flex;
//   flex-direction: row;
//   height: 100%;
//   background-color: #fff;
// }

.workspace {
  background-color: #FBFBFB;
  ;
  display: flex;
  flex-direction: column;
  width: min-content;
  box-sizing: border-box;
  flex: 1 0 auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 42px 34px;
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    color: #444658;
  }

  & .user-info {
    display: flex;
    align-items: center;

    border-radius: 40px;
    background: #F4F6F8;
    column-gap: 10px;
    padding: 12px 20px;

    &:hover {
      cursor: pointer;
    }

    &__email {
      font-size: 18px;
      font-weight: 300;
      color: #444658;
    }
  }
}

@media screen and (max-width: 950px) {
  .workspace {
    &__header {
      flex-direction: column;
      row-gap: 20px;
      padding: 20px 15px;
    }

    &__title {
      font-size: 20px;
    }

    & .user-info {
      padding: 8px 14px;
    }
  }

}

@media screen and (max-width: 676px) {
  .workspace {
    &__header {
      flex-direction: column;
      row-gap: 20px;
      padding: 20px 15px;
    }
  }
}