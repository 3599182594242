.btn.btn-notifications {
  border-radius: 5px;
  border: 1px solid #E58080;
  background-color: $white;
  color: $black;
  padding: 9px 13px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 9px;
  justify-content: center;
}

.workspace__title.notifications-title {
  display: flex;
  column-gap: 20px;
}

.notifications {
  & .notifications-list {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &__item {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      position: relative;

      padding-left: 45px;
      padding-right: 18px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 10px;
      border: 1px solid rgba(232, 237, 243, 0.80);
      background: #FFF;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 6px;

      & .text-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        font-size: 16px;
      }

      :first-child {
        font-weight: 300;
        color: #9B9B9B;
      }

      :last-child {
        font-weight: 400;
        color: #444658;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
      color: #444658;
      line-height: 127%;
    }

    &__control {
      display: flex;
      align-items: center;
      column-gap: 6px;
      font-size: 16px;

      .btn.btn-notifications {
        padding: 5px 9px;
        font-weight: 300;
      }

      a:first-child {
        color: #E58080;

        svg {
          height: 18px;
        }
      }

      a:last-child {
        border: 1px solid #129A97;
        color: $main-green;
        min-width: 180px;
        column-gap: 4px;
      }
    }

    &__icon {
      position: absolute;
      top: 27px;
      left: 19px;
    }
  }
}

@media screen and (max-width: 856px) {
  .notifications {
    & .notifications-list {
      &__header {
        flex-direction: column;
        row-gap: 15px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .notifications {
    & .notifications-list {
      &__item {
        padding: 8px;
      }

      &__control {
        flex-direction: column;
        row-gap: 10px;
      }

      &__icon {
        top: 8px;
        left: 8px;
      }
    }
  }
}