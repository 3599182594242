
[tooltip] {
  position: relative;
  display: inline-block;
}

/* Стрелка */
[tooltip]::before {
  position: absolute;
  content: "";
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip-position='left']::before {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translatey(-50%) rotate(-90deg);
}

[tooltip-position='top']::before {
  left: 50%;
}

[tooltip-position='buttom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip-position='right']::before {
  left: 100%;
  top: 50%;
  margin-left: -2px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: black;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  width: max-content;
  border-radius: 6px;
  pointer-events: none;
  padding: 5px 10px;
  z-index: 99;
  opacity: 0;
}

[tooltip-position='left']::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

[tooltip-position='top']::after {
  left: 50%;
}

[tooltip-position='buttom']::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

[tooltip-position='right']::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  transition-delay: 1.0s;
  transition: all 0.5s;
}