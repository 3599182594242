.terminal.finances {
  & .finances-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .statistics {
    padding-top: 35px;

    grid-template-columns: repeat(auto-fit, minmax(220px, 400px));
    gap: 20px;

    &__item {
      flex-direction: row !important;
      padding: 16px 20px !important;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
    }

    &__info {
      flex-direction: column;

      & p {
        font-size: 14px;
        font-weight: 500 !important;
        color: #A0AEC0 !important;
      }

      & span {
        font-size: 18px;
        font-weight: 300 !important;
        line-height: 140%;
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .terminal-info__control.terminal-info__control--transactions {
    column-gap: 7px;

    svg {
      min-width: 17px;
    }

    input,
    select {
      padding: 8px 17px !important;
    }

    select {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 40px;
      background: #fff;
      -webkit-box-shadow: 0 3.5px 5.5px 0 rgba(0, 0, 0, .02);
      box-shadow: 0 3.5px 5.5px 0 rgba(0, 0, 0, .02);
      padding: 8px 26px;
      border: 1px solid #dfdfdf;
      font-weight: 300;
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 762px) {
  .terminal-info__control--transactions {
    select {
      width: 100%;
    }
  }
}