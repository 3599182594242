.terminal.water-price {
  & .terminal-info {
    padding-top: 0;
  }

  .statistics {
    padding-top: 36px;

    &__item.water-price {
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 30px;
      padding-bottom: 17px;
      position: relative;

      border-radius: 15px;
      background: #FAFAFA;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

      & .statistics__info {
        flex-direction: column;

        *:not(p, span) {
          margin-bottom: 12px;
        }

        & p {
          font-size: 18px;
          font-weight: 400;
        }

        & span {
          font-size: 12px;
          font-weight: 400;
          color: #A0AEC0;
        }

        & .statistics__img {
          width: 64px;
          height: 64px;
        }
      }

      span {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }



  & .controls-block {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    position: absolute;
    right: 12px;
    top: 12px;

    & button {
      width: 23px;
      height: 23px;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;

      &:hover {
        cursor: pointer;

        svg {
          path {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }
        }
      }
    }

    & a {
      display: block;
      width: 23px;
      height: 23px;

      &:hover {
        cursor: pointer;

        svg {
          path {
            stroke: $main-green;
            transition: stroke 0.4s ease;
          }
        }
      }
    }
  }
}

.workspace__title.workspace__title--not-cup {
  &:hover {
    cursor: none;
  }

  ;
}

.modal {
  max-width: 367px;
  padding: 50px;
  border-color: transparent;
  border-radius: 20px;
  background: #FFF;
  // display: flex;
  flex-direction: column;
  row-gap: 25px;
  position: relative;

  &__title {
    font-size: 20px;
    font-weight: 450;
    color: #444658;
  }

  & .inputs-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #E2E8F0;
      background: #FFF;
      padding: 14px 20px;

      &::placeholder {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  & .form {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
  }

  & .setting-item__control {
    display: flex;
    column-gap: 10px;
  }

  &[open] {
    display: flex;
  }

  & .close-button {
    position: absolute;
    width: 17px;
    height: 17px;
    cursor: pointer;

    border: none;
    background-color: transparent;
    display: block;
    outline: none;
    padding: 0;
    margin: 0;

    top: 23px;
    right: 23px;
  }
}

*::backdrop {
  background: rgba(23, 23, 23, 0.70);
}