section[name='terminals'] {
  padding: 0 30px;

  .terminals {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &-table {


      padding: 25px;
      padding-top: 30px;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    }
  }
}

// ---------------------------------
.table {
  width: 100%;
}

.row__title {
  color: #53646f;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.row--top-40 {
  margin-top: 40px;
}

.row--top-20 {
  margin-top: 20px;
}

.table__th {
  color: #9eabb4;
  font-weight: 500;
  font-size: 12px;
  // text-transform: uppercase;
  // cursor: pointer;
  border: 0 !important;
  padding: 15px 8px !important;

  text-align: left;
  font-size: 16px;
  font-weight: 450;
}



.table-row {
  border-bottom: 1px solid #e4e9ea;
  background-color: #fff;
}

.table__th svg:hover {
  cursor: pointer;

  path {
    fill: #129A97;
  }
}

.table--select-all {
  width: 18px;
  height: 18px;
  padding: 0 !important;
  border-radius: 50%;
  border: 2px solid #becad2;
}

.table-row__td {
  padding: 12px 8px !important;
  vertical-align: middle !important;
  color: #53646f;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  line-height: 18px !important;
  border: 0 !important;
}

.table-row__img {
  width: 17px;
  height: 17px;
  display: inline-block;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: middle;
}


.table-row__info {
  display: inline-block;
  padding-left: 12px;
  vertical-align: middle;
}

.table-row__name {
  color: #53646f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}

.table-row__small {
  color: #9eabb4;
  font-weight: 300;
  font-size: 12px;
}

.table-row__policy {
  color: #53646f;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}

.table-row__p-status {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}


.table-row__status {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}


.table-row__progress {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}

.status:before {
  content: '';
  margin-bottom: 0;
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 50%;
}

.status--red:before {
  background-color: #e36767;
}

.status--red {
  color: #e36767;
}

.status--blue:before {
  background-color: #3fd2ea;
}

.status--blue {
  color: #3fd2ea;
}

.status--yellow:before {
  background-color: #ecce4e;
}

.status--yellow {
  color: #ecce4e;
}

.status--green {
  color: #6cdb56;
}

.status--green:before {
  background-color: #6cdb56;
}

.status--grey {
  color: #9eabb4;
}

.status--grey:before {
  background-color: #9eabb4;
}

.table__select-row {
  appearence: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  margin: 0 0 0 5px !important;
  vertical-align: middle;
  border: 2px solid #beccd7;
  border-radius: 50%;
  cursor: pointer;
}

.table-row--overdue {
  width: 3px;
  background-color: #e36767;
  display: inline-block;
  position: absolute;
  height: calc(100% - 24px);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.table-row__edit {
  width: 46px;
  padding: 8px 17px;
  display: inline-block;
  background-color: #daf3f8;
  border-radius: 18px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.table-row__bin {
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.table-row--red {
  background-color: #fff2f2;
}


@media screen and (max-width: 1240px) {
  .table__thead {
    display: none;
  }

  .table-row {
    display: inline-block;
    border: 0;
    background-color: #fff;
    width: calc(33.3% - 13px);
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .table-row__img {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
  }

  .table-row__td:before {
    content: attr(data-column);
    color: #9eabb4;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    padding: 4px 0px;
  }

  .table-row__info {
    display: block;
    padding-left: 0;
  }

  .table-row__td {
    display: block;
    text-align: center;
    padding: 8px !important;
  }

  .table-row--red {
    background-color: #fff2f2;
  }

  .table__select-row {
    display: none;
  }

  .table-row--overdue {
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(0%);
    height: 4px;
  }
}


@media screen and (max-width: 680px) {
  .table-row {
    width: calc(50% - 13px);
  }
}

@media screen and (max-width: 480px) {
  .table-row {
    width: 100%;
  }
}