.terminal.partners.terminal-notification {
  & .notification-item {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &__title {
      color: #444658;
      font-weight: 400;
      font-size: 25px;

      span {
        font-size: 16px;
        font-weight: 300;
      }
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
}