@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

*,
*::before,
*::after {
  scrollbar-color: #129A97 #000;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 10px;
  /* ширина для вертикального скролла */
  height: 10px;
  /* высота для горизонтального скролла */
  background-color: #000;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #129A97;
  border-radius: 10px;
  border: 3px solid black;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #157573;
}

[tooltip] {
  position: relative;
  display: inline-block;
}

/* Стрелка */
[tooltip]::before {
  position: absolute;
  content: "";
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip-position=left]::before {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translatey(-50%) rotate(-90deg);
}

[tooltip-position=top]::before {
  left: 50%;
}

[tooltip-position=buttom]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip-position=right]::before {
  left: 100%;
  top: 50%;
  margin-left: -2px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: black;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  width: max-content;
  border-radius: 6px;
  pointer-events: none;
  padding: 5px 10px;
  z-index: 99;
  opacity: 0;
}

[tooltip-position=left]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

[tooltip-position=top]::after {
  left: 50%;
}

[tooltip-position=buttom]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

[tooltip-position=right]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  transition-delay: 1s;
  transition: all 0.5s;
}

@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-Medium.woff) format("woff");
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-ExtraBold.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-Heavy.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaPT";
  src: url(../fonts/FuturaPT-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.wa {
  width: auto !important;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.w50 {
  width: 50% !important;
}

.h50 {
  height: 50% !important;
}

.p0 {
  padding: 0;
}

.pr0 {
  padding-right: 0;
}

.pl0 {
  padding-left: 0;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.mr0 {
  margin-right: 0;
}

.m0a {
  margin: 0 auto;
}

.df {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdr {
  flex-direction: row;
}

.aic {
  align-items: center;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}

.jfcc {
  justify-content: center;
}

.jfcsb {
  justify-content: space-between;
}

.jfcsa {
  justify-content: space-around;
}

.jfcfe {
  justify-content: flex-end;
}

.jfcfs {
  justify-content: flex-start;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: #fff;
  font-family: "FuturaPT", sans-serif;
  font-size: 1.6rem;
  color: #000;
  height: 100%;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  image-rendering: -webkit-optimize-contrast;
  font: inherit;
  font-size: inherit;
  color: inherit;
  outline: none;
}

img {
  vertical-align: top;
  object-fit: cover;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.btn {
  outline: none;
  border: 1px transparent;
  width: 100%;
  padding: 14px;
  border-radius: 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 450;
  background: #129A97;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease;
}
.btn:hover {
  cursor: pointer;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.1);
  background: #15b1ad;
}

@media screen and (max-width: 527px) {
  .btn {
    font-size: 16px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 23px;
  min-width: 38px;
}

/* hide custom checkbox and apply custom style to make toggle switch */
.switch input {
  display: none;
}

.slider {
  /* style toggle switch itself */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FF6C7D;
}

.slider::before {
  /* style toggle switch knob when OFF */
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1.34px;
  background-color: #fff;
  transition: 0.4s;
}

input[type=checkbox]:checked + .slider::before {
  /* style toggle switch knob when ON */
  -webkit-transform: translateX(15.2px);
  -ms-transform: translateX(15.2px);
  transform: translateX(15.2px);
}

input[type=checkbox]:checked + .slider {
  /* style toggle switch background when ON */
  background-color: #129A97;
}

/* apply border-radius to switch */
.slider.round {
  border-radius: 16px;
}

.slider.round::before {
  border-radius: 50%;
}

/* Blocks */
/* Контейнер */
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1460px) {
  .container {
    max-width: 1240px;
  }
}
@media screen and (max-width: 1260px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    max-width: calc(100% - 30px);
    margin: 0 15px;
  }
}
body {
  background: linear-gradient(258deg, #129A97 27.11%, #78DCD2 93.64%);
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
}
.login .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.6rem;
  color: #fff;
}
.login .logo__title {
  font-size: 40px;
  font-weight: 450;
  margin: 0;
}
.login .logo__img > svg {
  flex-shrink: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 85px;
  padding-right: 85px;
  padding-top: 35px;
  padding-bottom: 55px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
}
.login-form h2 {
  color: #272727;
  font-size: 25px;
  font-weight: 450;
  margin-bottom: 22px;
}
.login-form .inputs-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  margin-bottom: 30px;
}
.login-form .inputs-wrapper input {
  border-radius: 10px;
  padding: 15px 0 15px 44px;
  min-width: 325px;
  font-size: 16px;
  color: #555;
  font-weight: 400;
  border: 1px solid rgba(23, 158, 154, 0.25);
  background: rgba(244, 244, 244, 0.5);
  background-position: center left 14px;
  background-repeat: no-repeat;
}
.login-form .inputs-wrapper input:focus {
  border-color: rgba(15, 134, 130, 0.5);
  transition: border-color 0.4s ease;
}
.login-form .inputs-wrapper input[type=email] {
  background-image: url("../../assets/images/svg/login-email.svg");
}
.login-form .inputs-wrapper input[type=password] {
  background-image: url("../../assets/images/svg/login-password.svg");
}
.login-form .inputs-wrapper input.validate-error {
  border-color: #FF6C7D;
}
.login-form .inputs-wrapper input::placeholder {
  color: #A0AEC0;
}
.login-form .inputs-wrapper label {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  font-weight: 400;
  font-size: 12px;
  color: #A0AEC0;
  position: relative;
}
.login-form .inputs-wrapper label a {
  position: absolute;
  right: 0px;
  top: -1px;
  color: #555;
}
.login-form .inputs-wrapper label a:hover {
  text-decoration: underline;
}
.login-form .error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  column-gap: 8px;
}
.login-form .error span {
  color: #FF6C7D;
  font-size: 12px;
  font-weight: 450;
}
.login-form .error img {
  width: 17px;
  height: 17px;
}
.login-form .btn--back {
  background-color: transparent;
  color: #129A97;
  border: 1px solid #129A97;
  margin-top: 10px;
}

@media screen and (max-width: 527px) {
  .login-form {
    padding-left: 14px;
    padding-right: 14px;
    width: 100%;
    border-radius: 0px;
  }
  .login-form .inputs-wrapper {
    width: 100%;
  }
  .login-form .inputs-wrapper input {
    min-width: 274px;
    max-width: 100%;
  }
  .login-form h2 {
    font-size: 20px;
  }
  .login .logo__title {
    font-size: 30px;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #fff;
}

.nav {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding-top: 54px;
  padding-bottom: 28px;
  transition: all 0.8s ease-in-out;
}
.nav .logo {
  transition: all 0.8s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.6rem;
  color: #129A97;
  padding-bottom: 24px;
  padding-left: 43px;
  padding-right: 43px;
}
.nav .logo__title {
  font-size: 25px;
  font-weight: 450;
  margin: 0;
}
.nav .logo__img {
  width: 28px;
  height: 33px;
}
.nav .logo__img > svg {
  flex-shrink: 0;
}
.nav__hr-line {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav__links {
  transition: all 0.8s ease-in-out;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding-top: 52px;
  padding-bottom: 24px;
  padding-left: 43px;
}
.nav__links .link-item {
  transition: all 0.8s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav__links .link-item__link {
  transition: all 0.8s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  font-size: 18px;
  font-weight: 450;
  color: #444658;
  flex-grow: 1;
  transition: color 0.4s ease;
}
.nav__links .link-item__link.active, .nav__links .link-item__link:hover {
  color: #129A97;
}
.nav__links .link-item__link.active svg path, .nav__links .link-item__link:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.nav__links .link-item__link.active svg circle, .nav__links .link-item__link:hover svg circle {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.nav__links .link-item__link.active svg rect, .nav__links .link-item__link:hover svg rect {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.nav__links .link-item__link.active + .link-item__line {
  background-color: #129A97;
}
.nav__links .link-item__count {
  border-radius: 4px;
  background: #129A97;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 150%;
  margin-right: 8px;
}
.nav__links .link-item__line {
  width: 3px;
  height: 100%;
  border-radius: 5px;
  background-color: transparent;
}
.nav__links .link-item__line.active {
  background-color: #129A97;
}
.nav__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-right: 43px;
  padding-left: 43px;
}
.nav__footer .instructions {
  width: 100%;
  margin-bottom: 70px;
}
.nav__footer .instructions__link {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: 450;
  font-size: 18px;
  transition: color 0.4s ease;
}
.nav__footer .instructions__link:hover {
  color: #129A97;
}
.nav__footer .instructions__link:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.nav__footer .help {
  transition: all 0.8s ease-in-out;
  flex-grow: 1;
  background-color: #129A97;
  background-image: url("../../assets/images/svg/help-elipses.svg");
  background-repeat: no-repeat;
  background-position: center right;
  margin-bottom: 9px;
  padding: 16px 16px 0 16px;
  padding-bottom: 50px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}
.nav__footer .help p {
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.4px;
  margin-bottom: 7px;
}
.nav__footer .help a {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}
.nav__footer .help a:hover {
  text-decoration: underline;
}
.nav__footer .help__icon {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav__footer .copyright {
  transition: all 0.8s ease-in-out;
  font-size: 10px;
  color: #A7A7A7;
  font-weight: 400;
  flex-grow: 1;
  margin-bottom: 100px;
}
.nav__footer .btn-collapse {
  display: flex;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  padding: 0;
  font-size: 20px;
  font-weight: 300;
  color: #444658;
}
.nav__footer .btn-collapse svg {
  will-change: transform;
  transition: transform 0.4s ease;
}
.nav__footer .btn-collapse:hover {
  color: #129A97;
  transition: color 0.4s ease;
}
.nav__footer .btn-collapse:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.nav__footer .btn-collapse.btn-collapse--active {
  justify-content: flex-start;
  padding-left: 0px;
}
.nav__footer .btn-collapse.btn-collapse--active svg path {
  stroke: #129A97;
}
.nav__footer .btn-collapse.btn-collapse--active svg {
  transform: rotate(180deg);
}
.nav__footer .btn-collapse.btn-collapse--active span {
  display: none;
}
.nav.collapsed {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .logo__title {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .help {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .nav__footer {
  padding-right: 0px;
}
.nav.collapsed .link-item span {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .link-item__count {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .instructions__link span {
  transition: all 0.8s ease-in-out;
}
.nav.collapsed .copyright {
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}

@keyframes collapse {
  from {
    width: 100%;
    opacity: 1;
  }
  to {
    width: 0;
    opacity: 0;
    display: none;
    overflow: hidden;
  }
}
.workspace {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  width: min-content;
  box-sizing: border-box;
  flex: 1 0 auto;
}
.workspace__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 42px 34px;
}
.workspace__title {
  font-size: 30px;
  font-weight: 400;
  color: #444658;
}
.workspace .user-info {
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: #F4F6F8;
  column-gap: 10px;
  padding: 12px 20px;
}
.workspace .user-info:hover {
  cursor: pointer;
}
.workspace .user-info__email {
  font-size: 18px;
  font-weight: 300;
  color: #444658;
}

@media screen and (max-width: 950px) {
  .workspace__header {
    flex-direction: column;
    row-gap: 20px;
    padding: 20px 15px;
  }
  .workspace__title {
    font-size: 20px;
  }
  .workspace .user-info {
    padding: 8px 14px;
  }
}
@media screen and (max-width: 676px) {
  .workspace__header {
    flex-direction: column;
    row-gap: 20px;
    padding: 20px 15px;
  }
}
section[name=user] {
  padding: 0 30px;
}
section[name=user] .user {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  border-radius: 15px;
  background: #fff;
  padding: 35px;
}
section[name=user] .user__title {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
section[name=user] .user__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
section[name=user] .user__data {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
section[name=user] .user .inputs-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}
section[name=user] .user .inputs-wrapper input {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 0 16px;
  padding-top: 6px;
  padding-bottom: 10px;
}
section[name=user] .user .inputs-wrapper input::placeholder {
  font-size: 12px;
  font-weight: 300;
  color: #8E8E8E;
  line-height: normal;
}
section[name=user] .user__btn {
  width: 145px;
  padding: 7px 0;
  font-weight: 300;
  font-size: 14px;
  border-radius: 5px;
}

section[name=analytic] {
  padding: 0 30px;
}
section[name=analytic] .analytic .chart {
  padding-top: 40px;
}
section[name=analytic] .analytic .chart__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}
section[name=analytic] .analytic .chart__header h3 {
  font-size: 25px;
  font-weight: 400;
}
section[name=analytic] .analytic .chart__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
section[name=analytic] .analytic .chart__controls input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 8px 26px;
  border: 1px solid;
  border-color: #DFDFDF;
  font-weight: 300;
  font-size: 10px;
}
section[name=analytic] .analytic .chart__controls input::-webkit-calendar-picker-indicator {
  color: transparent;
  opacity: 1;
  background: url("../../assets/images/svg/date-icon.svg") no-repeat center;
  background-size: contain;
  order: 2;
}
section[name=analytic] .analytic .chart__controls input::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}
section[name=analytic] .analytic .chart__controls input:focus {
  border-color: #129A97;
  transition: border-color 0.4s ease;
}
section[name=analytic] .analytic .chart__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 8px 26px;
  color: #129A97;
  border: 1px solid;
  border-color: #129A97;
  font-weight: 400;
  font-size: 10px;
}
section[name=analytic] .analytic .chart__btn:hover {
  background: #15b1ad;
  color: #fff;
  border-color: #15b1ad;
}
section[name=analytic] .analytic .chart__graph {
  padding: 25px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}

.statistics {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 400px));
  gap: 30px;
}
.statistics__info p {
  font-size: 12px;
  font-weight: 700;
  color: #A0AEC0;
  line-height: 150%;
}
.statistics__info :nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-right: 4px;
  line-height: 140%;
}
.statistics__info :nth-child(3) {
  color: #129A97;
  font-size: 14px;
  font-weight: 700;
}
.statistics__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 20px 25px;
}
.statistics__img {
  border-radius: 12px;
  background: #129A97;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 13.4px;
}

@media screen and (max-width: 940px) {
  .chart__header {
    flex-direction: column;
    row-gap: 15px;
  }
}
@media screen and (max-width: 757px) {
  .chart__controls {
    flex-direction: column;
    row-gap: 15px;
  }
}
section[name=terminals] {
  padding: 0 30px;
}
section[name=terminals] .terminals {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
section[name=terminals] .terminals-table {
  padding: 25px;
  padding-top: 30px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}

.table {
  width: 100%;
}

.row__title {
  color: #53646f;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.row--top-40 {
  margin-top: 40px;
}

.row--top-20 {
  margin-top: 20px;
}

.table__th {
  color: #9eabb4;
  font-weight: 500;
  font-size: 12px;
  border: 0 !important;
  padding: 15px 8px !important;
  text-align: left;
  font-size: 16px;
  font-weight: 450;
}

.table-row {
  border-bottom: 1px solid #e4e9ea;
  background-color: #fff;
}

.table__th svg:hover {
  cursor: pointer;
}
.table__th svg:hover path {
  fill: #129A97;
}

.table--select-all {
  width: 18px;
  height: 18px;
  padding: 0 !important;
  border-radius: 50%;
  border: 2px solid #becad2;
}

.table-row__td {
  padding: 12px 8px !important;
  vertical-align: middle !important;
  color: #53646f;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  line-height: 18px !important;
  border: 0 !important;
}

.table-row__img {
  width: 17px;
  height: 17px;
  display: inline-block;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.table-row__info {
  display: inline-block;
  padding-left: 12px;
  vertical-align: middle;
}

.table-row__name {
  color: #53646f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}

.table-row__small {
  color: #9eabb4;
  font-weight: 300;
  font-size: 12px;
}

.table-row__policy {
  color: #53646f;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}

.table-row__p-status {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}

.table-row__status {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}

.table-row__progress {
  margin-bottom: 0;
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  color: #9eabb4;
}

.status:before {
  content: "";
  margin-bottom: 0;
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 50%;
}

.status--red:before {
  background-color: #e36767;
}

.status--red {
  color: #e36767;
}

.status--blue:before {
  background-color: #3fd2ea;
}

.status--blue {
  color: #3fd2ea;
}

.status--yellow:before {
  background-color: #ecce4e;
}

.status--yellow {
  color: #ecce4e;
}

.status--green {
  color: #6cdb56;
}

.status--green:before {
  background-color: #6cdb56;
}

.status--grey {
  color: #9eabb4;
}

.status--grey:before {
  background-color: #9eabb4;
}

.table__select-row {
  appearence: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  margin: 0 0 0 5px !important;
  vertical-align: middle;
  border: 2px solid #beccd7;
  border-radius: 50%;
  cursor: pointer;
}

.table-row--overdue {
  width: 3px;
  background-color: #e36767;
  display: inline-block;
  position: absolute;
  height: calc(100% - 24px);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.table-row__edit {
  width: 46px;
  padding: 8px 17px;
  display: inline-block;
  background-color: #daf3f8;
  border-radius: 18px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.table-row__bin {
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.table-row--red {
  background-color: #fff2f2;
}

@media screen and (max-width: 1240px) {
  .table__thead {
    display: none;
  }
  .table-row {
    display: inline-block;
    border: 0;
    background-color: #fff;
    width: calc(33.3% - 13px);
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .table-row__img {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
  }
  .table-row__td:before {
    content: attr(data-column);
    color: #9eabb4;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    padding: 4px 0px;
  }
  .table-row__info {
    display: block;
    padding-left: 0;
  }
  .table-row__td {
    display: block;
    text-align: center;
    padding: 8px !important;
  }
  .table-row--red {
    background-color: #fff2f2;
  }
  .table__select-row {
    display: none;
  }
  .table-row--overdue {
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(0%);
    height: 4px;
  }
}
@media screen and (max-width: 680px) {
  .table-row {
    width: calc(50% - 13px);
  }
}
@media screen and (max-width: 480px) {
  .table-row {
    width: 100%;
  }
}
.workspace .workspace__title a {
  vertical-align: middle;
  margin-right: 20px;
}
.workspace .workspace__title a:hover svg {
  circle-stroke: #129A97;
  circle-transition: stroke 0.4s ease;
}
.workspace .workspace__title a:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}

section[name=terminal] {
  padding: 0 30px;
}
section[name=terminal] .terminal {
  border-radius: 15px;
  background: #fff;
  padding: 30px 26px;
}
section[name=terminal] .terminal-select {
  padding: 20px 0;
}
section[name=terminal] .terminal-select select {
  border: none;
  outline: none;
  background-color: transparent;
}
section[name=terminal] .terminal-list {
  display: flex;
  align-items: center;
  column-gap: 48px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: flex-start;
}
section[name=terminal] .terminal-list__item {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  font-weight: 400;
  font-size: 20px;
  color: #747474;
}
section[name=terminal] .terminal-list__item:hover {
  cursor: pointer;
  color: #129A97;
}
section[name=terminal] .terminal-list__item.active {
  color: #129A97;
}
section[name=terminal] .terminal-list__item.active div {
  background: #129A97;
}
section[name=terminal] .terminal-list__line {
  height: 5px;
  border-radius: 20px;
  background: #F7F7F7;
}
section[name=terminal] .terminal .statistics__item {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  padding: 15px 16px;
}
section[name=terminal] .terminal .statistics__item {
  flex-direction: column;
  row-gap: 13px;
}
section[name=terminal] .terminal .statistics__info {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
section[name=terminal] .terminal .statistics__info p {
  font-size: 15px;
  font-weight: 300;
  color: #2D3748;
}
section[name=terminal] .terminal .statistics__info span {
  font-size: 18px;
  font-weight: 400;
}
section[name=terminal] .terminal .statistics__img {
  width: 50px;
  height: 50px;
}
section[name=terminal] .terminal-info {
  padding-top: 57px;
}
section[name=terminal] .terminal-info__title {
  font-size: 20px;
  font-weight: 400;
  color: #444658;
}
section[name=terminal] .terminal-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section[name=terminal] .terminal-info__header .btn {
  border-radius: 5px;
  border: 1px solid #129A97;
  background-color: #fff;
  color: #444658;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  column-gap: 10px;
  align-items: center;
}
section[name=terminal] .terminal-info__header .btn:hover {
  background-color: #129A97;
  color: #fff;
  transition: background-color 0.4s ease;
}
section[name=terminal] .terminal-info__header .btn:hover svg path {
  stroke: #fff;
}
section[name=terminal] .terminal-info__body {
  display: flex;
  justify-content: center;
  padding: 37px 30px;
  column-gap: 50px;
  row-gap: 20px;
}
section[name=terminal] .terminal-info__body .left-block,
section[name=terminal] .terminal-info__body .right-block {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
}
section[name=terminal] .terminal-info__body .center-line {
  width: 4px;
  height: auto;
  background-color: #DADADA;
}
section[name=terminal] .terminal-info__body .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  font-weight: 300;
  font-size: 14px;
  color: #2D3748;
}
section[name=terminal] .terminal-info__body .item__text {
  color: #000;
}
section[name=terminal] .terminal-info__body .item__text--green {
  color: #129A97;
}
section[name=terminal] .terminal-info__body .item__text--red {
  color: #FF6C7D;
}
section[name=terminal] .terminal-info__body .item__line {
  height: 1px;
  border-bottom: 1px dashed #D6D6D6;
  flex-grow: 1;
}

@media screen and (max-width: 1167px) {
  .terminal-info__body {
    flex-wrap: wrap;
  }
  .terminal-info__body .center-line {
    width: 100% !important;
    height: 2px !important;
  }
}
@media screen and (max-width: 790px) {
  section[name=terminal] {
    padding: 0 15px;
  }
  .terminal {
    padding: 15px 13px !important;
  }
  .terminal-info__body {
    padding: 18px 15px !important;
  }
  .terminal-info__header {
    flex-direction: column;
    row-gap: 20px;
  }
  .terminal-list__item {
    font-size: 16px !important;
  }
}
section[name=terminal] {
  padding: 0 30px;
}
section[name=terminal] .terminal .table-row__policy {
  font-size: 16px;
}
section[name=terminal] .terminal .table-row.table-row--chris.result td,
section[name=terminal] .terminal .table-row.table-row--chris.result td p {
  color: #2D3748;
  font-size: 16px;
  font-weight: 450;
}
section[name=terminal] .terminal .table-row__policy--error {
  color: #FF6C7D;
}
section[name=terminal] .terminal .table-row__policy--error .error {
  position: relative;
}
section[name=terminal] .terminal .table-row__policy--error .error__text {
  display: none;
  position: absolute;
  top: -42px;
  left: -91px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background: #FFF;
  padding: 3px;
  font-size: 12px;
  font-weight: 300;
  color: #676767;
  line-height: 1.1;
}
section[name=terminal] .terminal .table-row__policy--error .error__text svg {
  position: absolute;
  bottom: -7px;
  right: 8px;
}
section[name=terminal] .terminal .table-row__policy--error .error:hover {
  cursor: pointer;
}
section[name=terminal] .terminal .table-row__policy--error .error:hover .error__text {
  display: block;
}
section[name=terminal] .terminal .table-row__policy--error img {
  height: 11px;
  width: 11px;
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 3px;
}
section[name=terminal] .terminal-list {
  margin-bottom: 0;
}
section[name=terminal] .terminal-info {
  padding-top: 45px;
}
@media screen and (max-width: 976px) {
  section[name=terminal] .terminal-info__header {
    flex-direction: column;
    row-gap: 15px;
  }
}
section[name=terminal] .terminal-info__control--transactions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
section[name=terminal] .terminal-info__control--transactions input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 8px 26px;
  border: 1px solid;
  border-color: #DFDFDF;
  font-weight: 300;
  font-size: 10px;
}
section[name=terminal] .terminal-info__control--transactions input::-webkit-calendar-picker-indicator {
  color: transparent;
  opacity: 1;
  background: url("../../assets/images/svg/date-icon.svg") no-repeat center;
  background-size: contain;
  order: 2;
}
section[name=terminal] .terminal-info__control--transactions input::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}
section[name=terminal] .terminal-info__control--transactions input:focus {
  border-color: #129A97;
  transition: border-color 0.4s ease;
}
section[name=terminal] .terminal-info__control--transactions .chart__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 8px 26px;
  color: #129A97;
  border: 1px solid;
  border-color: #129A97;
  font-weight: 400;
  font-size: 10px;
}
section[name=terminal] .terminal-info__control--transactions .chart__btn:hover {
  background: #15b1ad;
  color: #fff;
  border-color: #15b1ad;
}
@media screen and (max-width: 762px) {
  section[name=terminal] .terminal-info__control--transactions {
    flex-direction: column;
    row-gap: 15px;
  }
}

tr td:not(:first-child) {
  text-align: center;
}

tr th:not(:first-child) {
  text-align: center;
}

.terminal .terminal-info__header .btn.btn--add {
  border: 1px solid #129A97;
  background: #129A97;
  color: #fff;
  padding: 7px 12px;
}
.terminal .terminal-info__header .btn.btn--add:hover {
  background: #fff;
  color: #129A97;
  border-color: #129A97;
  transition: all 0.4s ease;
}
.terminal .terminal-info__header .btn.btn--add:hover svg * {
  stroke: #129A97;
  transition: all 0.4s ease;
}

.hopper {
  display: flex;
  padding-top: 40px;
  column-gap: 40px;
}
.hopper-info {
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-bottom: 18px;
  padding-right: 29px;
  padding-left: 29px;
  border-radius: 15px;
  background: #FAFAFA;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}
.hopper-info__text {
  padding-top: 20px;
  text-align: center;
  color: #444658;
  font-weight: 300;
}
.hopper-info__body {
  display: flex;
}
.hopper-info__body p {
  font-size: 16px;
  font-weight: 400;
  color: #444658;
}
.hopper-info__body span {
  font-weight: 300;
}
.hopper-info__body .left,
.hopper-info__body .right {
  display: flex;
  flex-direction: column;
  width: 120px;
}
.hopper-info__body .left__item,
.hopper-info__body .right__item {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: dashed 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.hopper-info__body .left__item {
  border-right: dashed 1px rgba(0, 0, 0, 0.1);
}
.hopper-info__body .left__item p {
  margin-left: -15px;
}
.hopper-info__body .right {
  text-align: center;
}
.hopper-control {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}
.hopper-control__title {
  font-size: 20px;
  font-weight: 400;
}
.hopper-control__progress {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.hopper-control__progress progress {
  appearance: none;
  height: 6px;
}
.hopper-control__progress progress::-webkit-progress-value {
  background-color: #129A97;
  border-radius: 16px;
}
.hopper-control__progress progress::-webkit-progress-bar {
  background-color: #E2E8F0;
  border-radius: 16px !important;
}
.hopper-control__progress span {
  color: #129A97;
}
.hopper-control__button {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.hopper-control__button .img-wrapper {
  position: relative;
}
.hopper-control__button .img-wrapper:hover {
  cursor: pointer;
}
.hopper-control__button .img-wrapper:hover .error {
  display: block;
}
.hopper-control__button .img-wrapper .error {
  display: none;
  width: 274px;
  position: absolute;
  padding: 9px 13px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
  border: 1px solid #129A97;
  z-index: 999;
  bottom: 24px;
  left: -8px;
}
.hopper-control__button .img-wrapper .error svg {
  position: absolute;
  bottom: -6px;
  left: 10px;
}
.hopper-control__button img {
  width: 17px;
  height: 17px;
}
.hopper-control__button img:hover {
  cursor: pointer;
}
.hopper-control .btn {
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background-color: #fff;
  color: #129A97;
  font-size: 14px;
  font-weight: 300;
}
.hopper-control .btn:hover {
  background-color: #129A97;
  color: #fff;
}

@media screen and (max-width: 1110px) {
  .hopper {
    flex-direction: column;
    width: fit-content;
    row-gap: 20px;
  }
  .hopper-control .btn {
    width: max-content;
  }
}
.terminal-settings {
  padding-top: 40px;
  color: #444658;
}
.terminal-settings__key {
  font-size: 18px;
  font-weight: 400;
}
.terminal-settings__items {
  padding-top: 23px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 20px;
}
.terminal-settings .setting-item {
  border-radius: 15px;
  background: #FAFAFA;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 20px;
  display: flex;
  column-gap: 20px;
}
.terminal-settings .setting-item__title {
  font-size: 18px;
  font-weight: 450;
}
.terminal-settings .setting-item__text {
  font-size: 16px;
  font-weight: 300;
  color: #9E9E9E;
}
.terminal-settings .setting-item__image {
  padding-top: 14px;
}
.terminal-settings .setting-item__control {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.terminal-settings .setting-item__control--additional form {
  padding-top: 10px;
  display: flex;
  align-items: end;
  column-gap: 17px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.terminal-settings .setting-item__control--additional form label {
  font-size: 12px;
  font-weight: 300;
  color: #767676;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.terminal-settings .setting-item__control--additional form label input {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 7px;
  padding-left: 24px;
  max-width: 70px;
  color: #8E8E8E;
}
.terminal-settings .setting-item__control--additional form .btn.btn--add {
  border-radius: 5px;
  border: 1px solid #129A97;
  background: #129A97;
  padding: 6px 17px;
  font-size: 14px;
  font-weight: 300;
  max-width: 95px;
  transition: all 0.4s ease;
}
.terminal-settings .setting-item__control--additional form .btn.btn--add:hover {
  cursor: pointer;
  background: #fff;
  color: #129A97;
  border-color: #15b1ad;
}
.terminal-settings .setting-item__content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media screen and (max-width: 710px) {
  .setting-item {
    flex-direction: column;
    row-gap: 10px;
  }
  .setting-item__image {
    margin: 0 auto;
  }
  .setting-item__control--additional {
    flex-direction: column;
    row-gap: 10px;
    align-items: center !important;
    justify-content: center;
  }
}
.terminal.water-price .terminal-info {
  padding-top: 0;
}
.terminal.water-price .statistics {
  padding-top: 36px;
}
.terminal.water-price .statistics__item.water-price {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 30px;
  padding-bottom: 17px;
  position: relative;
  border-radius: 15px;
  background: #FAFAFA;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}
.terminal.water-price .statistics__item.water-price .statistics__info {
  flex-direction: column;
}
.terminal.water-price .statistics__item.water-price .statistics__info *:not(p, span) {
  margin-bottom: 12px;
}
.terminal.water-price .statistics__item.water-price .statistics__info p {
  font-size: 18px;
  font-weight: 400;
}
.terminal.water-price .statistics__item.water-price .statistics__info span {
  font-size: 12px;
  font-weight: 400;
  color: #A0AEC0;
}
.terminal.water-price .statistics__item.water-price .statistics__info .statistics__img {
  width: 64px;
  height: 64px;
}
.terminal.water-price .statistics__item.water-price span {
  font-size: 18px;
  font-weight: 400;
}
.terminal.water-price .controls-block {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  position: absolute;
  right: 12px;
  top: 12px;
}
.terminal.water-price .controls-block button {
  width: 23px;
  height: 23px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}
.terminal.water-price .controls-block button:hover {
  cursor: pointer;
}
.terminal.water-price .controls-block button:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}
.terminal.water-price .controls-block a {
  display: block;
  width: 23px;
  height: 23px;
}
.terminal.water-price .controls-block a:hover {
  cursor: pointer;
}
.terminal.water-price .controls-block a:hover svg path {
  stroke: #129A97;
  transition: stroke 0.4s ease;
}

.workspace__title.workspace__title--not-cup:hover {
  cursor: none;
}

.modal {
  max-width: 367px;
  padding: 50px;
  border-color: transparent;
  border-radius: 20px;
  background: #FFF;
  flex-direction: column;
  row-gap: 25px;
  position: relative;
}
.modal__title {
  font-size: 20px;
  font-weight: 450;
  color: #444658;
}
.modal .inputs-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.modal .inputs-wrapper input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #E2E8F0;
  background: #FFF;
  padding: 14px 20px;
}
.modal .inputs-wrapper input::placeholder {
  font-size: 14px;
  font-weight: 300;
}
.modal .form {
  display: flex;
  flex-direction: column;
  row-gap: 19px;
}
.modal__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 300;
}
.modal .setting-item__control {
  display: flex;
  column-gap: 10px;
}
.modal[open] {
  display: flex;
}
.modal .close-button {
  position: absolute;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: block;
  outline: none;
  padding: 0;
  margin: 0;
  top: 23px;
  right: 23px;
}

*::backdrop {
  background: rgba(23, 23, 23, 0.7);
}

.workspace__content.filters-system .filters-system__list {
  padding-top: 30px;
}
.workspace__content.filters-system .terminal-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.workspace__content.filters-system .terminal-select a.btn.btn--add {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 145px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background: #129A97;
  font-weight: 400;
  font-size: 14px;
}
.workspace__content.filters-system .filters-system__alert {
  padding: 12px 20px;
  border-radius: 10px;
  border: 2px dashed rgba(18, 154, 151, 0.5);
  background: #FFF;
  font-size: 16px;
  font-weight: 300;
  color: #444658;
}
.workspace__content.filters-system .filters-system__subtitle {
  font-size: 20px;
  font-weight: 400;
  color: #444658;
}
.workspace__content.filters-system .terminal-list__image-wrapper {
  height: 44px;
  width: 44px;
}
.workspace__content.filters-system .hopper-control__progress {
  align-items: flex-start;
}
.workspace__content.filters-system .hopper-control__progress span {
  font-weight: 400 !important;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--warning span {
  color: #FF6C7D;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--warning progress::-webkit-progress-value {
  background-color: #FF6C7D;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--attention span {
  color: #E1D800;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--attention progress::-webkit-progress-value {
  background-color: #E1D800;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--ok span {
  color: #129A97;
}
.workspace__content.filters-system .hopper-control__progress.hopper-control__progress--ok progress::-webkit-progress-value {
  background-color: #129A97;
}
.workspace__content.filters-system .table-row__td p {
  font-weight: 300;
}
.workspace__content.filters-system .table__th {
  color: #444658;
}
.workspace__content.filters-system .table-row__td span {
  font-size: 14px;
  font-weight: 300;
  color: #979797;
}
.workspace__content.filters-system .table-row__td.table-row__td--warning p {
  color: #FF6C7D;
  font-size: 14px;
  font-weight: 300;
}
.workspace__content.filters-system .table-row__td.table-row__td--ok p {
  color: #129A97;
  font-size: 14px;
  font-weight: 300;
}
.workspace__content.filters-system .table-row__td.table-row__td--attention p {
  color: #E1D800;
}
.workspace__content.filters-system .table-row__td button {
  font-weight: 400;
  font-size: 14px;
  padding: 8px 18px;
  border-radius: 5px;
  max-width: 150px;
  min-width: 160px;
}

.modal.modal--cancel .form.form--add-plane {
  row-gap: 8px;
  row-gap: 10px;
}
.modal.modal--cancel .form.form--add-plane p:first-child {
  color: #444658;
  font-size: 16px;
  font-weight: 300;
}
.modal.modal--cancel .form.form--add-plane .inputs-wrapper {
  padding-top: 10px;
  flex-direction: row;
  column-gap: 8px;
}
.modal.modal--cancel .form.form--add-plane .inputs-wrapper button:last-child {
  border: 1px solid #424242;
  background: #424242;
}
.modal.modal--cancel .form.form--add-plane .inputs-wrapper button:last-child:hover {
  background: #5c5c5c;
}

@media screen and (max-width: 1240px) {
  .workspace__content.filters-system .table-row__td button {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .workspace__content.filters-system .table-row__td .terminal-list__image-wrapper {
    margin: 0 auto;
  }
  .workspace__content.filters-system .table-row__td .hopper-control__progress {
    align-items: center;
  }
  .workspace__content.filters-system .terminal .terminals-table.terminals-table--report {
    padding: 10px 15px 20px;
  }
}
.workspace__content.filters-system .terminal-select.terminal-select--filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}
.workspace__content.filters-system .terminal-select.terminal-select--filters select {
  border-radius: 40px;
  border: 1px solid #DFDFDF;
  padding: 7px 12px;
}
.workspace__content.filters-system .terminal.partners.partners-card .terminals-table.terminals-table--history .table__th {
  color: #A0AEC0 !important;
}
.workspace__content.filters-system .terminal.partners.partners-card .terminals-table.terminals-table--history .table-row__td p {
  font-weight: 400;
  color: #444658;
}

@media screen and (max-width: 847px) {
  .workspace__content.filters-system .terminal-select.terminal-select--filters {
    flex-direction: column;
    row-gap: 20px;
  }
}
@media screen and (max-width: 627px) {
  .workspace__content.filters-system .terminal-select.terminal-select--filters select {
    width: 100%;
  }
}
.btn.btn-notifications {
  border-radius: 5px;
  border: 1px solid #E58080;
  background-color: #fff;
  color: #000;
  padding: 9px 13px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 9px;
  justify-content: center;
}

.workspace__title.notifications-title {
  display: flex;
  column-gap: 20px;
}

.notifications .notifications-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.notifications .notifications-list__item {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  position: relative;
  padding-left: 45px;
  padding-right: 18px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 10px;
  border: 1px solid rgba(232, 237, 243, 0.8);
  background: #FFF;
}
.notifications .notifications-list__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6px;
}
.notifications .notifications-list__header .text-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 16px;
}
.notifications .notifications-list__header :first-child {
  font-weight: 300;
  color: #9B9B9B;
}
.notifications .notifications-list__header :last-child {
  font-weight: 400;
  color: #444658;
}
.notifications .notifications-list__text {
  font-size: 14px;
  font-weight: 300;
  color: #444658;
  line-height: 127%;
}
.notifications .notifications-list__control {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 16px;
}
.notifications .notifications-list__control .btn.btn-notifications {
  padding: 5px 9px;
  font-weight: 300;
}
.notifications .notifications-list__control a:first-child {
  color: #E58080;
}
.notifications .notifications-list__control a:first-child svg {
  height: 18px;
}
.notifications .notifications-list__control a:last-child {
  border: 1px solid #129A97;
  color: #129A97;
  min-width: 180px;
  column-gap: 4px;
}
.notifications .notifications-list__icon {
  position: absolute;
  top: 27px;
  left: 19px;
}

@media screen and (max-width: 856px) {
  .notifications .notifications-list__header {
    flex-direction: column;
    row-gap: 15px;
    text-align: center;
  }
}
@media screen and (max-width: 710px) {
  .notifications .notifications-list__item {
    padding: 8px;
  }
  .notifications .notifications-list__control {
    flex-direction: column;
    row-gap: 10px;
  }
  .notifications .notifications-list__icon {
    top: 8px;
    left: 8px;
  }
}
.terminal.finances .finances-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.terminal.finances .statistics {
  padding-top: 35px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 400px));
  gap: 20px;
}
.terminal.finances .statistics__item {
  flex-direction: row !important;
  padding: 16px 20px !important;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}
.terminal.finances .statistics__info {
  flex-direction: column;
}
.terminal.finances .statistics__info p {
  font-size: 14px;
  font-weight: 500 !important;
  color: #A0AEC0 !important;
}
.terminal.finances .statistics__info span {
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 140%;
}
.terminal.finances .statistics__img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.terminal.finances .terminal-info__control.terminal-info__control--transactions {
  column-gap: 7px;
}
.terminal.finances .terminal-info__control.terminal-info__control--transactions svg {
  min-width: 17px;
}
.terminal.finances .terminal-info__control.terminal-info__control--transactions input,
.terminal.finances .terminal-info__control.terminal-info__control--transactions select {
  padding: 8px 17px !important;
}
.terminal.finances .terminal-info__control.terminal-info__control--transactions select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 40px;
  background: #fff;
  -webkit-box-shadow: 0 3.5px 5.5px 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 3.5px 5.5px 0 rgba(0, 0, 0, 0.02);
  padding: 8px 26px;
  border: 1px solid #dfdfdf;
  font-weight: 300;
  font-size: 10px;
}

@media screen and (max-width: 762px) {
  .terminal-info__control--transactions select {
    width: 100%;
  }
}
section[name=accounting] .accounting {
  display: flex;
  column-gap: 70px;
  border-radius: 15px;
  background: #FFF;
  padding: 40px 34px;
}
section[name=accounting] .accounting-data {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 100%;
}
section[name=accounting] .accounting-data__item {
  color: #2D3748;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
section[name=accounting] .accounting-data__item hr {
  margin: 0;
  height: 1px;
  background: #E2E8F0;
  margin-top: 10px;
  border: none;
  outline: none;
}
section[name=accounting] .accounting-data__item p {
  font-size: 16px;
  font-weight: 400;
}
section[name=accounting] .accounting-data__year {
  font-weight: 20;
  font-weight: 450;
}
section[name=accounting] .accounting-data__text-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6px;
}
section[name=accounting] .accounting-info {
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #EDF1FF;
  background: #F5FFFF;
  padding: 15px 24px;
  color: #333F5D;
  margin-top: 24px;
  border-left: 6px solid #DFF;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}
section[name=accounting] .accounting-info__title {
  font-size: 20px;
  font-weight: 600;
}
section[name=accounting] .accounting-info__subtitle {
  font-weight: 450;
}
section[name=accounting] .accounting-info__items {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
}
section[name=accounting] .accounting-info__item {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
section[name=accounting] .accounting-info__item p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (max-width: 927px) {
  section[name=accounting] {
    padding-left: 10px;
    padding-right: 10px;
  }
  section[name=accounting] .accounting {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    row-gap: 20px;
  }
}
.btn.btn--add.btn-partners {
  padding: 8px 18px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background-color: #fff;
  color: #444658;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.workspace__title.partners-title {
  display: flex;
  align-items: center;
  column-gap: 29px;
  line-height: 1.3;
}

section[name=terminal] .terminal.partners {
  padding: 14px 30px;
}
section[name=terminal] .terminal.partners .partners-control {
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
}
section[name=terminal] .terminal.partners .partners-control button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.wrapper.admin .modal {
  padding-top: 38px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 25px;
  overflow: hidden;
  max-width: none;
  min-width: 35dvw;
}
.wrapper.admin .modal .modal__content {
  overflow-y: auto;
  padding-right: 8px;
}
.wrapper.admin .modal .modal__content .form.form--add-plane {
  padding-bottom: 10px;
  padding-top: 10px;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs {
  row-gap: 20px;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__data, .wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__info, .wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__contacts {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 16px;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__wrapper input {
  padding: 7px 17px;
  padding-right: 0;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__wrapper label[for=password] {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__wrapper label[for=password] .generate-pass {
  border: none;
  outline: none;
  background-color: #129A97;
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  flex-shrink: 0;
}
.wrapper.admin .modal .modal__content .inputs-wrapper.partner-inputs .partner-inputs__wrapper label[for=password] .generate-pass img {
  width: 100%;
  height: 100%;
}
.wrapper.admin .modal .modal__content .setting-item__control {
  flex-direction: column;
  row-gap: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #444658;
}
.wrapper.admin .modal .modal__content .setting-item__control .additional-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.wrapper.admin .modal .modal__content .btn.modal__btn.btn--add {
  max-width: 160px;
  margin: 0 auto;
  margin-top: 20px;
}
.wrapper.admin .modal.modal--success {
  max-width: 360px;
  min-width: 300px;
}
.wrapper.admin .modal.modal--success .modal-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
.wrapper.admin .modal.modal--success .modal-wrapper img {
  max-width: 152px;
  max-height: 152px;
}
.wrapper.admin .modal.modal--success .modal-wrapper p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.wrapper.admin .modal.modal--success__content {
  row-gap: 19px;
}

.modal__content::-webkit-scrollbar {
  margin-right: 20px;
  width: 6px;
  /* ширина для вертикального скролла */
  height: 6px;
  /* высота для горизонтального скролла */
  background-color: #000;
}

/* ползунок скроллбара */
.modal__content::-webkit-scrollbar-thumb {
  margin-right: 20px;
  background-color: #129A97;
  border-radius: 10px;
  border: 2px solid transparent;
}

.modal__content::-webkit-scrollbar-thumb:hover {
  background-color: #157573;
}

.btn.btn--delete {
  font-size: 14px;
  font-weight: 300;
  color: #FF6C7D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  column-gap: 10px;
  border: 1px solid #FF6C7D;
}

.workspace.partners .workspace__header {
  column-gap: 8px;
}
.workspace.partners .workspace-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  row-gap: 20px;
}
.workspace.partners .workspace-wrapper h2 {
  flex-shrink: 0;
  margin-right: 10px;
}
.workspace.partners .workspace-wrapper a,
.workspace.partners .workspace-wrapper button {
  min-width: 145px;
  max-width: min-content;
  padding: 7px 12px;
}
.workspace.partners .workspace-wrapper a svg,
.workspace.partners .workspace-wrapper button svg {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
}

.terminal.partners.partners-card {
  background-color: #FBFBFB;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.terminal.partners.partners-card .btn.btn--add {
  background-color: #fff;
  color: #129A97;
}
.terminal.partners.partners-card .terminal-info__title {
  font-size: 25px;
}
.terminal.partners.partners-card .terminals-table {
  border-radius: 15px;
  background: #FFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 24px;
}
.terminal.partners.partners-card .profile-data {
  display: flex;
  column-gap: 20px;
}
.terminal.partners.partners-card .profile-data__form {
  border-radius: 15px;
  background: #FFF;
  padding: 35px;
}
.terminal.partners.partners-card .profile-data form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.terminal.partners.partners-card .profile-data form-block {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.terminal.partners.partners-card .profile-data form-block__title {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.terminal.partners.partners-card .profile-data form .form-block {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.terminal.partners.partners-card .profile-data form .inputs-wrapper {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.terminal.partners.partners-card .profile-data form .inputs-wrapper input {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 7px 17px;
  font-size: 12px;
  font-weight: 300;
}
.terminal.partners.partners-card .profile-data form .inputs-wrapper input::placeholder {
  color: #8E8E8E;
}
.terminal.partners.partners-card .profile-data form .inputs-wrapper input:focus {
  border-color: #129A97;
}
.terminal.partners.partners-card .profile-data form button {
  max-width: 160px;
}
.terminal.partners.partners-card .profile-data__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password, .terminal.partners.partners-card .profile-data__wrapper .authlist {
  padding: 30px 25px;
  border-radius: 15px;
  background: #FFF;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password {
  display: flex;
  column-gap: 40px;
  align-items: center;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control {
  display: flex;
  column-gap: 10px;
  align-items: flex-end;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control button {
  padding-top: 6px;
  padding-bottom: 6px;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control label {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control input {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 7px 17px;
  font-size: 12px;
  font-weight: 300;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control input::placeholder {
  color: #8E8E8E;
}
.terminal.partners.partners-card .profile-data__wrapper .change-password__control input:focus {
  border-color: #129A97;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist__list {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist__list__date {
  border-right: 1px solid #a6adb6;
  padding-right: 6px;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist__list__info {
  padding-left: 6px;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist__item {
  display: flex;
  padding-bottom: 14px;
  font-size: 14px;
  font-weight: 300;
}
.terminal.partners.partners-card .profile-data__wrapper .authlist__item:not(:last-child) {
  border-bottom: 1px solid #E2E8F0;
}
.terminal.partners.partners-card .terminal-list {
  margin-top: 20px;
  border-radius: 15px;
  background: #FFF;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.terminal.partners.partners-card .terminal-list__item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  border-radius: 15px;
  background: #FAFAFA;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 20px 28px;
  font-size: 16px;
  font-weight: 300;
  color: #444658;
}
.terminal.partners.partners-card .terminal-list__item:hover {
  cursor: default;
}
.terminal.partners.partners-card .terminal-list__item:hover p {
  color: #444658;
}
.terminal.partners.partners-card .terminal-list__subitem {
  display: flex;
  column-gap: 23px;
  align-items: center;
}
.terminal.partners.partners-card .terminal-list__subitem .terminal-list__image-wrapper {
  min-width: 44px;
  position: relative;
}
.terminal.partners.partners-card .terminal-list__subitem .terminal-list__image-wrapper::after {
  content: attr(data-count);
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #129A97;
  margin: 0 auto;
  margin-bottom: 10px;
  position: absolute;
  top: 0.2px;
  right: 0.2px;
  color: #fff;
}
.terminal.partners.partners-card .terminal-list__subitem:not(:first-child) {
  justify-content: center;
}
.terminal.partners.partners-card .terminal-list__subitem input {
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
}
.terminal.partners.partners-card .terminal-list__subitem a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.terminal.partners.partners-card .terminal-list__subitem button, .terminal.partners.partners-card .terminal-list__subitem a {
  font-size: 14px;
  font-weight: 300;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 5px;
  border: 1px solid #129A97;
  background: #FAFAFA;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}
.terminal.partners.partners-card .terminal-list__subitem button:hover, .terminal.partners.partners-card .terminal-list__subitem a:hover {
  cursor: pointer;
  background: #129A97;
  color: #fff;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1300px) {
  .terminal.partners.partners-card .profile-data {
    flex-direction: column;
    row-gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .terminal.partners.partners-card .profile-data__form {
    padding: 20px;
  }
  .terminal.partners.partners-card .profile-data__form .inputs-wrapper {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start !important;
  }
  .terminal.partners.partners-card .profile-data__form .inputs-wrapper.switch-wrapper {
    flex-direction: row;
    align-items: center !important;
  }
  .terminal.partners.partners-card .change-password {
    flex-direction: column;
    row-gap: 20px;
  }
}
.modal.modal--add-plane.modal__partner-card {
  max-width: 540px;
}
.modal.modal--add-plane.modal__partner-card .modal__content input[name=login] {
  background: #EAEAEA;
}
.modal.modal--add-plane.modal__partner-card .modal__content .setting-item__control h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.modal.modal--add-plane.modal__partner-card .modal__content .setting-item__control .switch-list {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.modal.modal--success.modal__partner-card {
  max-width: none !important;
}
.modal.modal--success.modal__partner-card .modal-wrapper {
  row-gap: 15px !important;
  align-items: flex-start !important;
}
.modal.modal--success.modal__partner-card .modal-wrapper p {
  font-size: 20px !important;
  font-weight: 400 !important;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card {
  padding-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 40px;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card label {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  font-size: 16px;
  font-weight: 300;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card label .label-wrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card label button {
  background-color: transparent;
  outline: none;
  border: none;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card input {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 7px 12px;
  min-width: 250px;
  color: #000;
  font-weight: 400;
}
.modal.modal--success.modal__partner-card .modal__content.modal__partner-card .btn.modal__btn.btn--add {
  margin: 0;
}

.accounting-info.accounting-info--shrink {
  flex-shrink: 0;
}
.accounting-info.accounting-info--shrink .accounting-info__items .accounting-info__item-wrapper {
  display: flex;
  column-gap: 4px;
}
.accounting-info.accounting-info--shrink .accounting-info__items .accounting-info__item-wrapper p {
  flex-shrink: 0;
}

.accounting-data__text-block .accounting-data__text select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.accounting-data__text-block .accounting-data__text select {
  border: none;
  outline: none;
  border-bottom: 1px dashed #000;
  padding-bottom: 6px;
  text-align: center;
}
.accounting-data__text-block .accounting-data__text select:hover {
  cursor: pointer;
}

@media screen and (max-width: 678px) {
  .accounting-info.accounting-info--shrink h3 {
    text-align: center;
  }
  .accounting-info.accounting-info--shrink .accounting-info__items .accounting-info__item-wrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
  }
  .accounting-info.accounting-info--shrink .accounting-info__items .accounting-info__item-wrapper p {
    font-weight: 500;
  }
}
.accounting-search {
  padding: 0 30px;
  padding-bottom: 16px;
}
.accounting-search form {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.accounting-search form input {
  font-size: 12px;
  font-weight: 300;
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 12px 16px;
  min-width: 180px;
  height: 100%;
}
.accounting-search form input::placeholder {
  color: #8E8E8E;
}
.accounting-search form button {
  border-radius: 5px;
  border: 1px solid #129A97;
  background: #129A97;
  padding: 11px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all 0.4s ease;
}
.accounting-search form button:hover {
  background: #fff;
  color: #129A97;
  border: 1px solid #129A97;
}

@media screen and (max-width: 950px) {
  .accounting-search {
    padding: 0 30px;
    padding-bottom: 16px;
  }
  .accounting-search form {
    flex-direction: column;
    row-gap: 16px;
  }
}
.terminal.partners.partners-card .terminals-table.terminals-table--report {
  padding-top: 1px;
}
.terminal.partners.partners-card .pagination-wrapper {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Стиль активной/текущей ссылки */
  /* Добавить серый цвет фона при наведении курсора мыши */
}
.terminal.partners.partners-card .pagination-wrapper .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.terminal.partners.partners-card .pagination-wrapper .pagination a.active {
  background-color: #129A97;
  color: white;
}
.terminal.partners.partners-card .pagination-wrapper .pagination a:hover:not(.active) {
  background-color: #ddd;
}
.terminal.partners.partners-card .table-row__policy {
  font-weight: 400;
}

@media screen and (max-width: 676px) {
  .terminal.partners.partners-card .pagination-wrapper {
    justify-content: center;
  }
  .terminal.partners.partners-card .pagination-wrapper .pagination a {
    padding: 4px 8px;
  }
}
.terminal.partners.terminal-notification .notification-item {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.terminal.partners.terminal-notification .notification-item__title {
  color: #444658;
  font-weight: 400;
  font-size: 25px;
}
.terminal.partners.terminal-notification .notification-item__title span {
  font-size: 16px;
  font-weight: 300;
}
.terminal.partners.terminal-notification .notification-item__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

.terminal.partners.terminal-notification .notification-create__form {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}
.terminal.partners.terminal-notification .notification-create__form input, .terminal.partners.terminal-notification .notification-create__form textarea {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  padding: 12px 13px;
  max-width: 390px;
  width: 100%;
}
.terminal.partners.terminal-notification .notification-create__form input::placeholder, .terminal.partners.terminal-notification .notification-create__form textarea::placeholder {
  font-size: 12px;
  font-weight: 300;
  color: #8E8E8E;
}
.terminal.partners.terminal-notification .notification-create__form textarea {
  resize: none;
}
.terminal.partners.terminal-notification .notification-create__form button {
  max-width: 390px;
  padding: 11px 50px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
}

section {
  padding: 0 30px;
}

@media screen and (max-width: 790px) {
  section {
    padding: 0 15px !important;
  }
}